import type { Validate } from 'react-hook-form';

import isEmpty from '@tinkoff/utils/is/empty';
import isString from '@tinkoff/utils/is/string';
import trim from '@tinkoff/utils/string/trim';

const pattern = /^[\s,()./’'№#\-a-zA-ZÑñ0-9]+$/;

interface FilipinoAddressOptions {
  text: string;
}

export function filipinoAddress<TFormValues>(
  options?: FilipinoAddressOptions
): Validate<unknown, TFormValues> {
  return function filipinoAddressValidator(value) {
    const text =
      options?.text ?? 'Use only letters and symbols ’ . - ( ) / # №';

    if (isString(value) && !isEmpty(value) && !trim(value).match(pattern)) {
      return text;
    }

    return undefined;
  };
}
