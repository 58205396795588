import { phMoneyProps } from '~shared/constants/ph-money-props';

import { formatMoney } from '@breeze-platform-ui/money/esm/utils';

import { getMinDownPayment, getMaxDownPayment } from './downpayment-math';

export const validateDownpaymentRange = (
  downpaymentValue: number,
  totalPrice: number,
  loanParams: {
    maxLoanAmount: number;
    minLoanAmount: number;
    minDownPaymentPercent: number;
    maxDownPaymentPercent: number;
  }
): string | undefined => {
  const minDownPayment = getMinDownPayment(totalPrice, loanParams);
  const maxDownPayment = getMaxDownPayment(totalPrice, loanParams);

  const isDownPaymentOutOfRange =
    downpaymentValue < minDownPayment || downpaymentValue > maxDownPayment;
  const isLoanAmountOutOfRange =
    totalPrice - downpaymentValue < loanParams.minLoanAmount ||
    totalPrice - downpaymentValue > loanParams.maxLoanAmount;

  if (isDownPaymentOutOfRange || isLoanAmountOutOfRange) {
    return `Must be ${getRange(minDownPayment, maxDownPayment)}`;
  }
};

function getRange(min: number, max: number) {
  const moneyConfig = {
    precision: 2,
    showSmalls: true,
    trimZeros: true,
    showCurrencyWithNull: true,
    ...phMoneyProps,
  };

  const formattedMin = formatMoney({
    value: min,
    ...moneyConfig,
  });

  const formattedMax = formatMoney({
    value: max,
    ...moneyConfig,
  });

  const minValue = `${formattedMin.currency}${formattedMin.numerals.join(',')}${formattedMin.smalls}`;
  const maxValue = `${formattedMax.currency}${formattedMax.numerals.join(',')}${formattedMax.smalls}`;
  return min === max ? minValue : `from ${minValue} to ${maxValue}`;
}
