import type { PropsWithChildren } from 'react';

import Clickable from '@breeze-platform-ui/clickable';
import { Int16ArrowRight } from '@breeze-platform-ui/iconsPack';

import styles from './action.module.css';

export type ActionProps = {
  icon: React.ReactNode;
  onClick?: (e: React.MouseEvent<any, MouseEvent>) => void;
  href?: string;
};

export const Action = ({
  icon,
  children,
  onClick,
  href,
}: ActionProps & PropsWithChildren) => {
  return (
    <Clickable
      className={styles.clickableContainer}
      href={href}
      onClick={(e) => {
        if (onClick && href) {
          e.preventDefault();
        }
        onClick?.(e);
      }}
    >
      <div className={styles.actionContainer}>
        {icon}
        <div className={styles.content}>{children}</div>
        <div className={styles.arrowButton}>
          <Int16ArrowRight theme={{ color: '#00000029' }} />
        </div>
      </div>
      <div className={styles.separator} />
    </Clickable>
  );
};
