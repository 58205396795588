import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  getAttemptsLimitMessage,
  getOtpRequestDelaySeconds,
  getRequestDelayTime,
  isAttemptsLimitReached,
  useResendCountdown,
} from '~features/auth/otp/lib';

import { useOnMountEffect } from '~shared/hooks';
import { combineValidators } from '~shared/lib/combine-validators';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import { LoadingButton } from '@breeze-platform-ui/button';
import { Box } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import { InputPhone } from '@breeze/rhf-adapters';

import styles from './agent-phone.module.css';

type Props = {
  onSubmit: (phoneNumber: string) => void;
  isLoading: boolean;
  initialPhone?: string | null;
};

export const AgentPhone = ({ initialPhone, onSubmit, isLoading }: Props) => {
  const { control, handleSubmit, setError, clearErrors } = useForm<{
    phoneNumber: string;
  }>({
    mode: 'all',
    defaultValues: { phoneNumber: initialPhone ?? undefined },
  });
  const [timer, startCountdown, cancelCountdown] = useResendCountdown();
  const [isAttemptsLimitError, setAttemptsLimitError] = useState(false);

  useEffect(() => {
    if (
      initialPhone &&
      timer &&
      isAttemptsLimitReached('login', initialPhone)
    ) {
      setAttemptsLimitError(true);
      setError('phoneNumber', { message: getAttemptsLimitMessage(timer) });
    } else {
      setAttemptsLimitError(false);
      clearErrors('phoneNumber');
    }
  }, [timer, initialPhone, clearErrors, setError]);

  useOnMountEffect(() => {
    if (initialPhone) {
      const delay = getOtpRequestDelaySeconds('login', initialPhone);

      if (delay) {
        startCountdown(delay);
      }
    }
  });

  const handleFormSubmit = ({ phoneNumber }: { phoneNumber: string }) => {
    onSubmit(phoneNumber);
  };

  const showResendTimer = !!timer && !isAttemptsLimitError && !isLoading;

  return (
    <Screen header={<HeaderWithLogo />}>
      <div className={styles.titleContainer}>
        <ScreenTitle title="Log in&nbsp;to&nbsp;your account" />
      </div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Controller
          name="phoneNumber"
          control={control}
          rules={{
            required: 'Please, enter your mobile number',
            validate: combineValidators(
              validators.phoneLength(),
              validators.phoneFormat()
            ),
          }}
          render={({ field, ...props }) => (
            <InputPhone
              {...props}
              label="Mobile number"
              field={{
                ...field,
                onChange: (...args) => {
                  timer && cancelCountdown();
                  field.onChange(...args);
                },
              }}
            />
          )}
        />

        {showResendTimer && (
          <Box margin="0 0 16px 0">
            <Text color="rgba(0, 0, 0, 0.4)" size={13}>
              Request a&nbsp;new code in&nbsp;{getRequestDelayTime(timer)}
            </Text>
          </Box>
        )}

        <LoadingButton
          loading={isLoading}
          wide
          size="l"
          type="submit"
          disabled={!!timer}
        >
          Get a code
        </LoadingButton>
      </form>
    </Screen>
  );
};
