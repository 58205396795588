import { useDi } from '~app/providers/di-provider';

import type { Enabled, Query } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { Status } from '../api';

export const useGenerateAgreementQuery = (options?: {
  enabled: Enabled<'OK', Error>;
}) => {
  const { agreementGenerationApi } = useDi();
  return useQuery({
    queryKey: ['generateAgreement'],
    queryFn: () => agreementGenerationApi.generate(),
    gcTime: 0,
    ...options,
  });
};

export const useGetAgreementStatusQuery = (options?: {
  enabled: Enabled<Status, Error>;
  refetchInterval:
    | number
    | false
    | ((query: Query<Status, Error>) => number | false | undefined);
}) => {
  const { agreementGenerationApi } = useDi();
  return useQuery({
    queryKey: ['agreementStatus'],
    queryFn: () => agreementGenerationApi.getStatus(),
    gcTime: 0,
    ...options,
  });
};
