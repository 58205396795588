import type { AbstractOption } from '~shared/types/abstract-option';

const options = [
  { title: 'Facebook', value: 'FACEBOOK' },
  { title: 'TikTok', value: 'TIKTOK' },
  { title: 'Other', value: 'OTHER' },
] as const;

export type SocialPlatformValue = (typeof options)[number]['value'];
export const socialPlatformOptions = options as any as AbstractOption[];
