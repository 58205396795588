import type { PropsWithChildren } from 'react';
import { useEffect, useRef } from 'react';

export const ScrollIntoView = ({ children }: PropsWithChildren) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    elementRef.current?.scrollIntoView();
  }, []);

  return <div ref={elementRef}>{children}</div>;
};
