import { idDocumentTypeNames } from '~entities/person';

import type { RadioOption } from '~shared/types/radio-option';

const options = [
  {
    label: 'UMID',
    value: idDocumentTypeNames.UMID,
  },
  {
    label: idDocumentTypeNames.SSS,
    value: 'SSS',
  },
  {
    label: idDocumentTypeNames.TIN,
    value: 'TIN',
  },
  {
    label: idDocumentTypeNames.GSIS,
    value: 'GSIS',
  },
] as const;

export type AdditionalIdDocumentTypeValue = (typeof options)[number]['value'];
export type AdditionalIdDocumentTypeTitle = (typeof options)[number]['label'];
export const additionalIdDocumentOptions =
  options as unknown as RadioOption<AdditionalIdDocumentTypeValue>[];
