import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useAnalytics } from '~app/providers/analytics-provider';
import { usePortalConfig } from '~app/providers/portal-config-provider';

import { SelectPos, ShowPosHelp } from '~features/select-pos';

import type { Pos } from '~entities/pos';
import { selectedPosAtom } from '~entities/pos';

import { BasicHeader } from '~shared/ui/basic-header';
import { Screen } from '~shared/ui/screen';

import Button from '@breeze-platform-ui/button';
import { Row } from '@breeze-platform-ui/layout';
import { useNotifications } from '@pfa/front-notifications';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

type NavigationState = {
  openedFrom?: 'account' | 'main';
};

export const SelectPosPage = () => {
  const { customerServicePhone } = usePortalConfig();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl') || '/applications';
  const backUrl = searchParams.get('backUrl');

  const { state: navigationState } = useLocation();
  const { openedFrom } = (navigationState as NavigationState) || {};
  const analytics = useAnalytics();

  const [selectedPos, setSelectedPos] = useAtom(selectedPosAtom);
  const [currentPos, setCurrentPos] = useState<Pos | null>(selectedPos);

  const notifications = useNotifications();

  const handleStoreSubmit = () => {
    if (!currentPos) {
      notifications.error('Store is not selected');
      return;
    }
    setSelectedPos(currentPos);

    if (openedFrom) {
      analytics.trackPosChanged({ screen: openedFrom });
    }

    queryClient.resetQueries({
      queryKey: ['agent-incentive'],
    });
    navigate(`${redirectUrl}`);
  };

  return (
    <Screen
      header={
        <BasicHeader
          mainContent="Select your store"
          secondaryContent={
            <ShowPosHelp customerServicePhone={customerServicePhone} />
          }
        />
      }
      footer={
        <Row alignCross="center" alignMain="between" grow={1} gaps="xxl">
          {backUrl && (
            <Button
              size="m"
              wide
              theme="secondary"
              onClick={() => navigate(backUrl)}
            >
              Cancel
            </Button>
          )}
          <Button size="m" wide onClick={handleStoreSubmit}>
            Done
          </Button>
        </Row>
      }
    >
      <SelectPos
        currentPos={currentPos}
        onChange={(pos) => setCurrentPos(pos)}
      />
    </Screen>
  );
};
