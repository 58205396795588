import type { FieldErrors } from 'react-hook-form';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { useRevalidate } from '~shared/hooks';
import { combineValidators } from '~shared/lib/combine-validators';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { SectionTitle } from '~shared/ui/section-title';
import { validators } from '~shared/validators';

import { Col } from '@breeze-platform-ui/layout';
import { Input, Select } from '@breeze/rhf-adapters';

import { JobDetails } from './job-details';
import { UnemployedReason } from './unemployed-reason';

import { EMPLOYMENT_STATUS_FIELD_NAME } from '../../constants/field-names';
import type {
  EmploymentStatusValue,
  NatureOfWorkValue,
  UnemployedReasonValue,
  WorkExperienceValue,
} from '../../lib';
import { employmentStatusOptions } from '../../lib/employment-status-options';

export interface FormValues {
  employmentStatus: EmploymentStatusValue;
  jobTitle?: string;
  natureOfWork?: NatureOfWorkValue;
  natureOfWorkOther?: string;
  workExperience?: WorkExperienceValue;
  unemployedReason?: UnemployedReasonValue;
  unemployedReasonOther?: string;
  companyName?: string;
}

type Props = {
  name: string;
  initialValue?: Partial<FormValues>;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onSubmit: (values: FormValues) => void;
  onClose: () => void;
  onFieldCompleted?: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
};

export const EmploymentDetails = ({
  name,
  initialValue = {},
  onFieldCompleted,
  onPrev,
  onSubmit,
  onClose,
}: Props) => {
  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: initialValue,
    shouldUnregister: true,
  });
  const { control, handleSubmit, getValues, watch } = methods;
  const [employmentStatus] = watch(['employmentStatus']);

  useRevalidate(methods);

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };
  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={handleClickButtonPrev} />}
      >
        <ScreenTitle title="Employment details" />
        <FormProvider {...methods}>
          <Controller
            name="employmentStatus"
            control={control}
            rules={{
              required: 'Select one option',
            }}
            render={(fieldProps) => (
              <Select
                label={EMPLOYMENT_STATUS_FIELD_NAME}
                options={employmentStatusOptions}
                {...fieldProps}
              />
            )}
          />

          {employmentStatus === 'UNEMPLOYED' ? (
            <UnemployedReason />
          ) : (
            <JobDetails />
          )}

          {employmentStatus === 'OFW' && (
            <Col margin="12px 0 0 0" gaps={16} alignCross="stretch">
              <SectionTitle title="Company details" />
              <Controller
                name="companyName"
                control={control}
                rules={{
                  validate: combineValidators(
                    validators.maxLength({ maxLength: 150 }),
                    validators.latinOnly()
                  ),
                }}
                render={(fieldProps) => (
                  <Input label="Company name (if any)" {...fieldProps} />
                )}
              />
            </Col>
          )}
        </FormProvider>
      </Screen>
    </form>
  );
};
