import { Fragment } from 'react';

import { phMoneyProps } from '~shared/constants/ph-money-props';

import { formatMoney as format } from '@breeze-platform-ui/money/esm/utils';

export const formatMoney = (value: number) => {
  const { numerals, smalls, currency } = format({
    value,
    precision: 2,
    showSmalls: true,
    trimZeros: true,
    showCurrencyWithNull: true,
    ...phMoneyProps,
  });

  return (
    <>
      {currency}
      {numerals.map((numeral, index) => (
        <Fragment key={index}>
          {index ? ',' : ''}
          {numeral}
        </Fragment>
      ))}
      {smalls}
    </>
  );
};
