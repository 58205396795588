import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import type { ApplicationProcessStatus } from '~entities/application';

import Button from '@breeze-platform-ui/button';
import Island from '@breeze-platform-ui/island';

import { ProcessStatusTitle } from '../process-status-title/process-status-title';

type Props = {
  processStatus: ApplicationProcessStatus;
  agreementId?: string;
};
export const Approved = ({ processStatus, agreementId }: Props) => {
  const { id } = useParams();

  const navigate = useNavigate();
  const analytics = useCustomerAnalytics();

  useEffect(() => {
    if (id) {
      analytics.trackApprovedScreenShown({ applicationId: id });
    }
  }, [analytics, id]);

  const handleButtonClick = () => {
    if (agreementId) {
      navigate(`/applications/${id}/loan-details`);
    } else {
      navigate(`/applications/${id}/product-confirmation`);
    }
  };
  return (
    <Island
      theme="shadow"
      title={<ProcessStatusTitle processStatus={processStatus} />}
      footerBody={
        <Button wide size="m" onClick={handleButtonClick}>
          {agreementId ? 'See loan details' : 'Complete application'}
        </Button>
      }
    />
  );
};
