import { Controller, useFormContext } from 'react-hook-form';

import type { ConversationDetails } from '~entities/marketing-lead';

import { useRevalidate } from '~shared/hooks/use-revalidate';
import { combineValidators } from '~shared/lib/combine-validators';
import { validators } from '~shared/validators';

import { Box, Col } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import { InputDate, Select, Textarea } from '@breeze/rhf-adapters';

import { feedbackOptions } from '../lib/feedback-options';

export const UpdateMarketingLead = () => {
  const methods = useFormContext<ConversationDetails>();
  const { control } = methods;

  useRevalidate(methods);

  return (
    <div>
      <Controller
        name="feedback"
        control={control}
        render={(props) => (
          <Select
            {...props}
            cleanable
            options={feedbackOptions}
            label="Lead’s feedback"
            onClean={() => methods.setValue('feedback', '' as any)}
          />
        )}
      />
      <Controller
        name="plannedDateOfVisit"
        control={control}
        rules={{
          validate: combineValidators(
            validators.isDate(),
            validators.minDate({
              minDate: new Date(),
              message: 'Planned date of visit should be in the future',
            })
          ),
        }}
        render={(props) => (
          <InputDate
            {...props}
            label="Planned date of visit"
            withValidityMark={false}
            cleanable
            field={{
              ...props.field,
              value: props.field.value || '',
              onChange: (_event, params) => {
                props.field.onChange(params?.maskedValue ?? '');
              },
            }}
          />
        )}
      />
      <Controller
        name="comment"
        control={control}
        rules={{
          validate: combineValidators(
            validators.latinText(),
            validators.maxLength({ maxLength: 500 })
          ),
        }}
        render={(props) => (
          <Col gaps={8} alignCross="stretch">
            <Box padding="8px 0">
              <Text bold size={15}>
                Agent’s comments
              </Text>
            </Box>
            <Textarea {...props} rows={3} />
          </Col>
        )}
      />
    </div>
  );
};
