import { phMoneyProps } from '~shared/constants/ph-money-props';

import Money from '@breeze-platform-ui/money';

import styles from './gift-payment-badge.module.css';
import rocketImg from './rocket.svg';

export const GiftPaymentBadge = ({ amount }: { amount: number }) => {
  return (
    <div className={styles.container} data-qa-type="gift-payment-badge">
      <img src={rocketImg} alt="" width={14} height={14} />
      <p className={styles.money}>
        <Money value={amount} {...phMoneyProps} />
      </p>
    </div>
  );
};
