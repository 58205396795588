import { PropsWithChildren, useCallback, useMemo } from 'react';

import { isAuthorizedAtom } from '~entities/auth';

import { isAuthError, isNetworkFail, isServerError } from '~shared/errors';

import { useNotifications } from '@pfa/front-notifications';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

export const PortalQueryClientProvider = ({ children }: PropsWithChildren) => {
  const notificatons = useNotifications();
  const setIsAuthorized = useSetAtom(isAuthorizedAtom);
  const onError = useCallback(
    (err: unknown) => {
      const error = err as Error;

      if (isNetworkFail(error) || isServerError(error)) {
        notificatons.error('Something went wrong. Please try again');
      }

      if (isAuthError(error)) {
        setIsAuthorized(false);
      }
    },
    [notificatons, setIsAuthorized]
  );

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // retry can be a function
            // after negative cases agreement we can make retries depending on error code
            retry: false,
            networkMode: 'always',
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
          },
          mutations: {
            retry: false,
            networkMode: 'always',
            onError,
          },
        },
        queryCache: new QueryCache({
          onError,
        }),
      }),
    [onError]
  );

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
