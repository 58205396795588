import { Loader } from '~shared/ui/loader';
import { Screen } from '~shared/ui/screen';

export const AppLoadingPage = () => {
  return (
    <Screen>
      <Loader />
    </Screen>
  );
};
