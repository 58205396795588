import type { MarketingLead } from '~entities/marketing-lead';
import { feedbackToTextMap } from '~entities/marketing-lead/lib/feedback-to-text-map';

import { format } from 'date-fns';

import styles from './lead-conversation-details.module.css';

export const LeadConversationDetails = ({
  feedback,
  plannedDateOfVisit,
  comment,
}: NonNullable<MarketingLead['conversationDetails']>) => (
  <>
    {feedback && (
      <div className={styles.section}>
        <p className={styles.sectionTitle}>Customer’s feedback</p>
        <p className={styles.sectionFootnote}>{feedbackToTextMap[feedback]}</p>
      </div>
    )}
    {plannedDateOfVisit && (
      <div className={styles.section}>
        <p className={styles.sectionTitle}>Planned date of visit</p>
        <p className={styles.sectionFootnote}>
          {format(plannedDateOfVisit, 'MM/dd/yyyy')}
        </p>
      </div>
    )}
    {comment && (
      <div className={styles.section}>
        <p className={styles.sectionTitle}>Agent’s comments</p>
        <p className={styles.sectionFootnote}>{comment}</p>
      </div>
    )}
  </>
);
