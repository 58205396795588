export const LunchIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.944 2.091c-.46 0-.77.365-.77.815V6.79c0 .442-.227.653-.576.653-.318 0-.505-.262-.505-.574l-.018-4.034c0-.51-.444-.835-.856-.835s-.857.326-.857.835v4.034c0 .312-.205.574-.523.574-.348 0-.575-.211-.575-.653V2.906c0-.45-.31-.815-.77-.815-.461 0-.707.398-.757.786-.05.39-.305 3.17-.333 4.947-.029 1.778 1.219 2.817 1.93 3.148.486.225.838.71.838 1.295L5.88 22h2.676l-.29-9.733c0-.584.35-1.07.837-1.295.71-.33 1.959-1.37 1.93-3.148-.028-1.777-.283-4.557-.334-4.947-.05-.388-.295-.785-.756-.786ZM16.782 2.154c-1.851 0-3.815 1.976-3.815 5.079 0 1.777 1.343 3.22 1.93 3.74.575.507.838.71.838 1.294l-.29 9.734h2.675l-.29-9.734c0-.583.263-.787.838-1.295.586-.519 1.93-1.962 1.93-3.74 0-3.102-1.964-5.078-3.816-5.078Z"
    />
  </svg>
);
