import { useAgentInfo } from '~entities/agent';
import { useGetDailySchedule } from '~entities/shedule/api/get-daily-schedule';

import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Loader } from '~shared/ui/loader';
import { NavigationFooter } from '~shared/ui/navigation-footer';
import { Screen } from '~shared/ui/screen';

import { Row } from '@breeze-platform-ui/layout';
import { format } from 'date-fns';

import styles from './empty-schedule.module.css';

export const EmptyScheduleGuard = ({ children }: React.PropsWithChildren) => {
  const agentTypeQuery = useAgentInfo({
    select: (info) => info.employmentType,
  });
  const dailyScheduleQueryEnabled = agentTypeQuery.data === 'FULL_TIME';
  const dailyScheduleQuery = useGetDailySchedule(
    format(new Date(), 'yyyy-MM-dd'),
    {
      enabled: dailyScheduleQueryEnabled,
    }
  );

  if (
    agentTypeQuery.isLoading ||
    (dailyScheduleQueryEnabled && dailyScheduleQuery.isLoading)
  ) {
    return (
      <Screen header={<HeaderWithLogo />} footer={<NavigationFooter />}>
        <Loader centered />
      </Screen>
    );
  }

  if (
    dailyScheduleQueryEnabled &&
    dailyScheduleQuery.isSuccess &&
    !dailyScheduleQuery.data
  )
    return (
      <Screen header={<HeaderWithLogo />} footer={<NavigationFooter />}>
        <Row width="100%" height="100%" alignCross="center" alignMain="center">
          <h2 className={styles.title}>
            No schedule. <br />
            Contact your ASM
          </h2>
        </Row>
      </Screen>
    );

  return <>{children}</>;
};
