import type { Validate } from 'react-hook-form';

import isEmpty from '@tinkoff/utils/is/empty';
import isString from '@tinkoff/utils/is/string';

interface Options {
  text?: string;
  length?: number;
}

const phPhoneNumberRegEx = /^\+63([1-7]\d{8}|8[0-8]\d{7}|89\d{8}|9\d{9})$/;

export function phoneFormatPh<TFormValues>(
  options: Options = {}
): Validate<unknown, TFormValues> {
  return function phoneFormatPhValidator(value) {
    const { text = 'Must be a valid Philippine mobile number' } = options;

    if (!isString(value) || isEmpty(value)) {
      return undefined;
    }

    if (!phPhoneNumberRegEx.test(value)) {
      return text;
    }

    return undefined;
  };
}
