import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  activeApplicationIdAtom,
  applicationsKeys,
} from '~entities/application';

import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export const ActiveApplicationGuardHOC = ({
  children,
  onAccessDenied,
}: React.PropsWithChildren<{ onAccessDenied: () => void }>) => {
  const queryClient = useQueryClient();
  const activeApplicationId = useAtomValue(activeApplicationIdAtom);
  const currentApplicationId = useParams().id;

  useEffect(() => {
    if (currentApplicationId && activeApplicationId !== currentApplicationId) {
      // Reset authorized application query in case token was upgraded in another tab
      queryClient.resetQueries({
        queryKey: applicationsKeys.authorizedApplication(),
      });
      onAccessDenied();
    }
  }, [currentApplicationId, activeApplicationId, onAccessDenied, queryClient]);

  if (currentApplicationId && activeApplicationId === currentApplicationId) {
    window.clarity?.('set', 'application_id', activeApplicationId);
  }

  return <>{children}</>;
};
