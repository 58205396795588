import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type {
  AttachAttendanceFilePayload,
  AttendanceLogPayload,
  AttendanceLogResponse,
  AttendanceStatusResponse,
  SendExplanatoryNotePayload,
} from './attendance-api';
import { attendanceKeys } from './attendance-keys';
import { gatewayAttendanceApi } from './gateway-attendance-api';

export function useLogAttendance(
  options?: Pick<
    UseMutationOptions<AttendanceLogResponse, Error, AttendanceLogPayload>,
    'onSuccess' | 'onError' | 'onSettled'
  >
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: AttendanceLogPayload) => {
      try {
        const result = await gatewayAttendanceApi.logAttendance(payload);

        queryClient.setQueryData<Partial<AttendanceStatusResponse>>(
          attendanceKeys.status(),
          {
            status: payload.type,
            posId: payload.posId,
          }
        );

        return result;
      } catch (error) {
        queryClient.invalidateQueries({ queryKey: attendanceKeys.status() });
        throw error;
      }
    },
    retry: 3,
    ...options,
  });
}

export function useAttachAttendancePhoto() {
  return useMutation({
    mutationFn: async (payload: AttachAttendanceFilePayload) => {
      try {
        await gatewayAttendanceApi.uploadPhoto(payload);
        // eslint-disable-next-line no-empty
      } catch (_err) {}

      return 'OK';
    },
  });
}

export function useSendExplanatoryNote() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: SendExplanatoryNotePayload) => {
      const result = await gatewayAttendanceApi.sendExplanatoryNote(payload);
      const statusKey = attendanceKeys.status();
      queryClient.setQueryData<Partial<AttendanceStatusResponse>>(statusKey, {
        ...queryClient.getQueryData<AttendanceStatusResponse>(statusKey),
        explanatoryNoteExists: true,
      });

      return result;
    },
    retry: 3,
  });
}
