import { useEffect } from 'react';

import { UseQueryResult } from '@tanstack/react-query';

export const useQueryStatusHandlers = <TData, TError = Error>({
  queryResult,
  onSuccess,
  onError,
  onSettled,
}: {
  queryResult: UseQueryResult<TData, TError>;
  onSuccess?: (data: TData) => void;
  onError?: (error: TError) => void;
  onSettled?: (data: TData | undefined, error: TError | null) => void;
}) => {
  useEffect(() => {
    if (queryResult.isSuccess) {
      onSuccess?.(queryResult.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult.isSuccess]);

  useEffect(() => {
    if (queryResult.isError) {
      onError?.(queryResult.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult.isError]);

  useEffect(() => {
    if (!queryResult.isFetching) {
      onSettled?.(queryResult.data, queryResult.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult.isFetching, queryResult.data, queryResult.error]);
};
