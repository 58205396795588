import { type PropsWithChildren, useCallback } from 'react';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { environment } from '~app/environment';
import { useOpeningTime } from '~app/providers/opening-time-provider';

import { AppLoadingPage } from '~widgets/app-loading-page';

import { useAgentInfo } from '~entities/agent';
import { isEmployeeRoleAllowedAtom, isAuthorizedAtom } from '~entities/auth';

import { useAtomValue } from 'jotai';

export const AuthGuard = ({ children }: PropsWithChildren) => {
  const openingTime = useOpeningTime();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isLoginPage = pathname.startsWith('/login');
  const isAccessDeniedPage = pathname.startsWith('/access-denied');

  const isAuthorized = useAtomValue(isAuthorizedAtom);
  const hasAccess = useAtomValue(isEmployeeRoleAllowedAtom);

  const isAgentTypeQueryEnabled = !!isAuthorized && !!hasAccess;
  const agentType = useAgentInfo({
    enabled: isAgentTypeQueryEnabled,
    select: (info) => info.employmentType,
  });

  const shouldRedirectToLogin = !isAuthorized && !isLoginPage;

  const shouldRedirectToAccessDenied =
    isAuthorized && !hasAccess && !isAccessDeniedPage;

  // agent was granted access while being on access denied page
  const shouldRedirectToMain =
    isAuthorized &&
    hasAccess &&
    agentType.isSuccess &&
    (isLoginPage || isAccessDeniedPage);

  const shouldReloadPage = useCallback(
    () =>
      new Date().getTime() - openingTime >
      environment.USER_INACTIVE_THRESHOLD * 60 * 1000,
    [openingTime]
  );

  const mainPage =
    agentType.data !== 'CONTRACTOR' ? '/account' : '/applications';

  useEffect(() => {
    if (shouldRedirectToLogin) {
      if (shouldReloadPage()) {
        window.location.replace('/login');
      } else {
        navigate('/login');
      }

      return;
    }

    // invalid role
    if (shouldRedirectToAccessDenied) {
      navigate('/access-denied');
      return;
    }

    if (shouldRedirectToMain) {
      if (shouldReloadPage()) {
        window.location.replace(mainPage);
      } else {
        navigate(mainPage);
      }
    }
  }, [
    shouldRedirectToLogin,
    shouldRedirectToAccessDenied,
    shouldRedirectToMain,
    shouldReloadPage,
    navigate,
    mainPage,
  ]);

  const shouldRedirect =
    shouldRedirectToLogin ||
    shouldRedirectToAccessDenied ||
    shouldRedirectToMain;

  if (shouldRedirect) {
    return <AppLoadingPage />;
  }

  return <>{children}</>;
};
