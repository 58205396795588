import { useAgentInfo } from '~entities/agent';
import type { WorkingStatus } from '~entities/attendance';
import { getMatchingWorkingStatus } from '~entities/attendance';
import { isWorkingStatus, useAttendanceStatus } from '~entities/attendance';
import { useGetDailySchedule } from '~entities/shedule';

import { format } from 'date-fns';

type ShouldForceTimeInResponse = {
  shouldForce: boolean;
  isLoading?: boolean;
  nextStatus?: WorkingStatus;
};
export function useShouldForceAttendanceLog(): ShouldForceTimeInResponse {
  const attendanceStatusQuery = useAttendanceStatus();
  const agentTypeQuery = useAgentInfo({
    select: (info) => info.employmentType,
  });
  const dailyScheduleQuery = useGetDailySchedule(
    format(new Date(), 'yyyy-MM-dd'),
    {
      enabled: agentTypeQuery.data === 'FULL_TIME',
    }
  );

  const status = attendanceStatusQuery.data?.status;
  const isWorking = status && isWorkingStatus(status);
  const nextWorkingStatus =
    !isWorking && status ? getMatchingWorkingStatus(status) : undefined;

  return {
    shouldForce:
      attendanceStatusQuery.isSuccess &&
      !isWorking &&
      agentTypeQuery.isSuccess &&
      agentTypeQuery.data !== 'CONTRACTOR' &&
      dailyScheduleQuery.isSuccess &&
      dailyScheduleQuery.data?.type === 'WORK',
    isLoading:
      attendanceStatusQuery.isLoading ||
      agentTypeQuery.isLoading ||
      (dailyScheduleQuery.fetchStatus !== 'idle' &&
        dailyScheduleQuery.isLoading),
    nextStatus: nextWorkingStatus,
  };
}
