import { useNavigate } from 'react-router-dom';

import Clickable from '@breeze-platform-ui/clickable';
import { Int24NavBack } from '@breeze-platform-ui/iconsPack';

interface Props {
  href?: string;
  onClick?: () => void;
}

export const NavBackButton = ({ href, onClick }: Props) => {
  const navigate = useNavigate();

  const handleClick: React.MouseEventHandler = (event) => {
    event.preventDefault();
    if (href) {
      navigate(href);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <Clickable
      style={{
        backgroundColor: 'transparent',
        cursor: 'pointer',
        border: 'none',
      }}
      href={href}
      onClick={handleClick}
    >
      <Int24NavBack
        theme={{ color: 'var(--tds-color-accent)' }}
        cursor="pointer"
      />
    </Clickable>
  );
};
