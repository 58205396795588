import type { SocialLeadListItem } from '~entities/social-lead';

import Text from '@breeze-platform-ui/text';

import styles from './social-lead-item.module.css';

export const SocialLeadItem = ({
  firstName,
  lastName,
  comment,
}: SocialLeadListItem) => {
  return (
    <div>
      <div className={styles.header}>
        <Text size={15} overflowEllipsis align="left">
          {firstName}&nbsp;{lastName}
        </Text>
      </div>
      {comment && (
        <Text color="#0000008A" size={13} overflowEllipsis align="left">
          {comment}
        </Text>
      )}
    </div>
  );
};
