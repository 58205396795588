import { formatMobileNumber } from '~shared/lib/format-phone-number';

import Clickable from '@breeze-platform-ui/clickable';
import Text from '@breeze-platform-ui/text';

import styles from './contact-lead.module.css';
import messsageIcon from './message.svg';
import phoneIcon from './phone.svg';

type Props = {
  firstName: string;
  lastName: string;
  phone?: string;
};
export const ContactLead = ({ firstName, lastName, phone }: Props) => (
  <div>
    <div className={styles.name}>
      <Text tagName="h2" overflowEllipsis>
        {firstName}&nbsp;{lastName}
      </Text>
    </div>
    {phone && (
      <>
        <a href={`tel:${phone}`} className={styles.phone}>
          <img src={phoneIcon} alt="" width={16} height={16} />
          {formatMobileNumber(phone)}
        </a>
        <Clickable
          className={styles.messageButtonContainer}
          innerClassName={styles.messageButton}
          href={`sms:${phone}`}
        >
          <div className={styles.iconContainer}>
            <img src={messsageIcon} alt="" width={16} height={16} />
          </div>
          Message
        </Clickable>
      </>
    )}
  </div>
);
