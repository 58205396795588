import { environment } from '~app/environment';

import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import type { DailySchedule } from '../schedule';

type Options = { host: string };

export type GetDailyScheduleParams = {
  // example: 2024-06-24
  date: string;
};

export type GetDailyScheduleResponse = {
  schedule?: DailySchedule;
};

export class GatewayScheduleApi {
  scpClient: ScpClient;
  host: string;

  constructor(scpClient: ScpClient, options: Options) {
    this.scpClient = scpClient;
    this.host = options.host;
  }

  getDailySchedule(
    params: GetDailyScheduleParams
  ): Promise<GetDailyScheduleResponse> {
    return this.makeRequest<GetDailyScheduleResponse>({
      method: 'get-by-day',
      payload: params,
    });
  }

  private makeRequest<T>(params: Partial<RequestParams> & { method: string }) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: 'v2',
        domain: 'wfm',
        service: 'web/schedule',
        withCredentials: true,
        withLeadingDomain: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayScheduleApi = new GatewayScheduleApi(webRequestClient, {
  host: environment.API_HOST,
});
