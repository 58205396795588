import type { PropsWithChildren } from 'react';

import styles from './island.module.css';

export const Island = ({
  children,
  style,
  dataQaType,
}: PropsWithChildren<
  Partial<{
    dataQaType: string;
    style: React.HTMLAttributes<HTMLDivElement>['style'];
  }>
>) => {
  return (
    <div data-qa-type={dataQaType} className={styles.container} style={style}>
      {children}
    </div>
  );
};
