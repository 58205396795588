import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useUploadFileMutation } from '~entities/file';
import type { ManufacturerIdType } from '~entities/product';

import { validators } from '~shared/validators';

import { AttachFile, type FileItem, FILE_STATUS } from '@breeze/rhf-adapters';

import type { LoanDetailsFormValues } from '../../types';

type Props = {
  applicationId: string;
  type: ManufacturerIdType;
  detectedFile?: File;
  index: number;
};

const typeText: Record<ManufacturerIdType, string> = {
  IMEI: 'IMEI',
  SERIAL_NUMBER: 'serial number',
};

export const IdPhotoUpload = ({
  applicationId,
  type,
  detectedFile,
  index,
}: Props) => {
  const { mutateAsync: upload } = useUploadFileMutation();
  const { control, setValue } = useFormContext<LoanDetailsFormValues>();

  useEffect(() => {
    if (detectedFile) {
      setValue(
        `itemsDetails.${index}.image`,
        [
          {
            status: FILE_STATUS.LOADING,
            file: detectedFile,
            previewUrl: URL.createObjectURL(detectedFile),
          },
        ],
        { shouldValidate: true, shouldDirty: true, shouldTouch: true }
      );
      upload({
        applicationId,
        fileItem: {
          docType: type,
          frontImage: detectedFile,
        },
      })
        .then(() => {
          setValue(`itemsDetails.${index}.image`, [
            {
              status: FILE_STATUS.SUCCESS,
              file: detectedFile,
              previewUrl: URL.createObjectURL(detectedFile),
            },
          ]);
        })
        .catch((error) => {
          setValue(`itemsDetails.${index}.image`, [
            {
              status: FILE_STATUS.ERROR,
              file: detectedFile,
              message: error.message,
            },
          ]);
        });
    }
  }, [detectedFile, setValue, upload, applicationId, type, index]);

  const uploadFile = async (file: FileItem) => {
    return upload({
      applicationId,
      fileItem: {
        docType: type,
        frontImage: file.file,
      },
    });
  };

  return (
    <Controller
      control={control}
      name={`itemsDetails.${index}.image`}
      rules={{
        validate: validators.fileRequired({
          message: `Please, take a photo of the ${typeText[type]}`,
        }),
      }}
      render={(fieldProps) => (
        <AttachFile
          {...fieldProps}
          uploadFile={uploadFile}
          single
          preview
          accept="image/*"
          capture="environment"
          labels={{
            common: `Take a photo of the device ${typeText[type]}`,
            desktop: ' ',
            dragging: ' ',
          }}
        />
      )}
    />
  );
};
