import { useState, type PropsWithChildren } from 'react';

import { LogAttendance } from '~features/log-attendance';

import { useAgentInfo } from '~entities/agent';
import { AttendanceWarning, useAttendanceStatus } from '~entities/attendance';

import { nonNullableValue } from '~shared/types/non-nullable-value';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Loader } from '~shared/ui/loader';
import { Screen } from '~shared/ui/screen';

import styles from './check-attendance-guard.module.css';
import { useShouldForceAttendanceLog } from './lib/use-should-force-attendance-log';

export const CheckAttendanceGuardHOC = ({
  children,
  onCancel,
}: PropsWithChildren & { onCancel: () => void }) => {
  const attendanceStatusQuery = useAttendanceStatus();
  const agentTypeQuery = useAgentInfo({
    select: (info) => info.employmentType,
  });

  const [isAttendanceLogInProgress, setAttendanceLogInProgress] =
    useState(false);
  const shouldCheckAttendance = useShouldForceAttendanceLog();

  if (isAttendanceLogInProgress) {
    return (
      <LogAttendance
        status={nonNullableValue(shouldCheckAttendance.nextStatus)}
        onSuccess={() => setAttendanceLogInProgress(false)}
        onCancel={onCancel}
      />
    );
  }

  const isDataLoading =
    !attendanceStatusQuery.isSuccess ||
    !agentTypeQuery.isSuccess ||
    shouldCheckAttendance.isLoading;

  if (isDataLoading || shouldCheckAttendance.shouldForce) {
    return (
      <div className={styles.screenContainer}>
        <Screen header={<HeaderWithLogo />}>
          {isDataLoading ? (
            <Loader centered overlay />
          ) : (
            <AttendanceWarning
              onContinue={() => setAttendanceLogInProgress(true)}
              onCancel={onCancel}
            />
          )}
        </Screen>
      </div>
    );
  }

  return <>{children}</>;
};
