import type { PropsWithChildren } from 'react';

import logo from '~shared/images/chinese-ny-logo.svg';

import { Row } from '@breeze-platform-ui/layout';

export const HeaderWithNYLogo = ({ children }: PropsWithChildren) => {
  return (
    <Row
      height={36}
      width="100%"
      alignCross="center"
      alignMain="between"
      data-qa-type="header"
      gaps={8}
    >
      <img src={logo} alt="" height="46" width="153" className="mt-[-10px]" />
      {children}
    </Row>
  );
};
