import { Skeleton } from '~shared/ui/skeleton';

import styles from './skeleton.module.css';

export const SocialLeadsSkeleton = () => {
  return (
    <div className={styles.list}>
      {[...Array(3)].map((_, index) => (
        <div className={styles.section} key={index}>
          <Skeleton className={styles.date} />
          {[...Array(4)].map((_1, index2) => (
            <div className={styles.item} key={index2}>
              <Skeleton className={styles.name} />
              <Skeleton className={styles.note} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
