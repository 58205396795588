import { useEffect } from 'react';

import { initiateOrder } from '~entities/agreement';
import {
  useGenerateAgreementQuery,
  useGetAgreementStatusQuery,
} from '~entities/document';

export const useGenerateAgreement = ({
  enabled,
  applicationId,
}: {
  enabled: boolean;
  applicationId: string;
}) => {
  const generateAgreement = useGenerateAgreementQuery({ enabled });
  const agreementStatus = useGetAgreementStatusQuery({
    enabled: !generateAgreement.isFetching && generateAgreement.isSuccess,
    refetchInterval: (query) =>
      query.state.data === 'IN_PROGRESS' ? 1000 : false,
  });

  const isLoading =
    enabled &&
    (generateAgreement.isFetching ||
      agreementStatus.isFetching ||
      agreementStatus.data === 'IN_PROGRESS');

  const isAgreementStatusError =
    agreementStatus.isError ||
    agreementStatus.data === 'ERROR' ||
    agreementStatus.data === 'NOT_FOUND';

  const isError = generateAgreement.isError || isAgreementStatusError;

  const isSuccess =
    generateAgreement.isSuccess && agreementStatus.data === 'SUCCESS';

  const refetch = () => {
    generateAgreement.refetch();
  };

  useEffect(() => {
    if (isSuccess) {
      initiateOrder(applicationId);
    }
  }, [isSuccess, applicationId]);

  return { isLoading, isSuccess, isError, refetch };
};
