import type { FieldErrors, Validate } from 'react-hook-form';
import { useForm, FormProvider } from 'react-hook-form';

import type { PhoneType } from '~entities/person';

import type { YesNoOptionValue } from '~shared/constants/yes-no-options';
import { useRevalidate } from '~shared/hooks';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import { CompanyDetails } from './company-details';
import { CompanyReference } from './company-reference';

import type {
  EmployedWithCompanyStatusValue,
  ReferenceBusinessValue,
  ReferenceEmployeeValue,
  ReferenceSelfEmployedValue,
} from '../../lib';
import { isSelfEmployedFn } from '../../lib';
import { hasOwnBusinessFn } from '../../lib';
import { isEmployeeFn } from '../../lib';

type ReferenceValue =
  | ReferenceEmployeeValue
  | ReferenceBusinessValue
  | ReferenceSelfEmployedValue;

export type FormValues = {
  haveEmployees?: YesNoOptionValue;
  companyName?: string;
  reference?: ReferenceValue;
  referenceName?: string;
  workPhone?: string;
};

type Props = Readonly<{
  name: string;
  employmentStatus: EmployedWithCompanyStatusValue;
  initialValue?: Partial<FormValues>;
  validatePhoneUnicity: (
    phoneType: PhoneType
  ) => Validate<string | undefined, FormValues>;
  onSubmit: (values: FormValues) => void;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onFieldCompleted?: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onClose: () => void;
  onPhoneUpdate: (phone: string | undefined, type: PhoneType) => void;
}>;

export const AdditionalEmploymentDetails = ({
  name,
  employmentStatus,
  initialValue,
  validatePhoneUnicity,
  onSubmit,
  onPrev,
  onFieldCompleted,
  onClose,
  onPhoneUpdate,
}: Props) => {
  const isEmployee = isEmployeeFn(employmentStatus);
  const hasOwnBusiness = hasOwnBusinessFn(employmentStatus);
  const isSelfEmployed = isSelfEmployedFn(employmentStatus);
  const shouldAskForReference = isEmployee || hasOwnBusiness || isSelfEmployed;

  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: initialValue ?? {},
  });
  const { getValues, handleSubmit } = methods;

  useRevalidate(methods);

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={handleClickButtonPrev} />}
      >
        <ScreenTitle title="More employment details" />
        <FormProvider {...methods}>
          <CompanyDetails employmentStatus={employmentStatus} />
          {shouldAskForReference && (
            <CompanyReference
              employmentStatus={employmentStatus}
              onPhoneUpdate={onPhoneUpdate}
              validatePhoneUnicity={validatePhoneUnicity}
            />
          )}
        </FormProvider>
      </Screen>
    </form>
  );
};
