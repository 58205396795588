import React, { createContext } from 'react';

import type {
  AmplitudeAnalytics,
  AmplitudeCustomerAnalytics,
  AmplitudeCalculatorAnalytics,
} from '~entities/amplitude-analytics';

const AnalyticsContext = createContext<null | AmplitudeAnalytics>(null);
const CustomerAnalyticsContext =
  createContext<null | AmplitudeCustomerAnalytics>(null);
interface Props {
  analytics: AmplitudeAnalytics;
}
const CalculatorAnalyticsContext =
  createContext<null | AmplitudeCalculatorAnalytics>(null);

export const AnalyticsProvider = (props: React.PropsWithChildren<Props>) => {
  const { analytics, children } = props;
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const CustomerAnalyticsProvider = (
  props: React.PropsWithChildren<{ analytics: AmplitudeCustomerAnalytics }>
) => {
  const { analytics, children } = props;
  return (
    <CustomerAnalyticsContext.Provider value={analytics}>
      {children}
    </CustomerAnalyticsContext.Provider>
  );
};

export const CalculatorAnalyticsProvider = (
  props: React.PropsWithChildren<{ analytics: AmplitudeCalculatorAnalytics }>
) => {
  const { analytics, children } = props;
  return (
    <CalculatorAnalyticsContext.Provider value={analytics}>
      {children}
    </CalculatorAnalyticsContext.Provider>
  );
};

export const useAnalytics = (): AmplitudeAnalytics => {
  const maybeAnalytics = React.useContext(AnalyticsContext);

  if (maybeAnalytics === null) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }

  return maybeAnalytics;
};

export const useCustomerAnalytics = (): AmplitudeCustomerAnalytics => {
  const maybeAnalytics = React.useContext(CustomerAnalyticsContext);

  if (maybeAnalytics === null) {
    throw new Error(
      'useCustomerAnalytics must be used within a AnalyticsProvider'
    );
  }

  return maybeAnalytics;
};

export const useCalculatorAnalytics = (): AmplitudeCalculatorAnalytics => {
  const maybeAnalytics = React.useContext(CalculatorAnalyticsContext);

  if (maybeAnalytics === null) {
    throw new Error(
      'useCalculatorAnalytics must be used within a CalculatorAnalyticsProvider'
    );
  }

  return maybeAnalytics;
};
