import type { ValidationRule } from 'react-hook-form';

import type {
  MainIdDocumentTypeValue,
  OtherIdDocumentTypeValue,
} from '~widgets/loan-application-form/id-document-type/lib/id-document-type-options';

import identity from '@tinkoff/utils/function/identity';

import type {
  MaskedInputOnChangeEvent,
  MaskedInputOnChangeParams,
} from '@breeze-platform-ui/input';

import { documentNumberFieldNames, NUMBER_FORMAT_ERROR } from '../constants';

type Mask = {
  value: string;
  transitions: Record<string, RegExp>;
};

type IdDocumentMeta = {
  validators: Record<string, ValidationRule>;
  mask: Mask;
  transform: (
    event: MaskedInputOnChangeEvent,
    params: MaskedInputOnChangeParams<string>
  ) => string | MaskedInputOnChangeEvent;
  placeholder: string;
  label: string;
};

type IdDocumentTypeValue = MainIdDocumentTypeValue | OtherIdDocumentTypeValue;
export const idDocumentMetaMap: {
  [key in IdDocumentTypeValue]: IdDocumentMeta;
} = {
  UMID: {
    validators: {
      pattern: {
        value: /^\d{4}-\d{7}-\d$/,
        message: NUMBER_FORMAT_ERROR,
      },
    },
    mask: {
      value: '0000-0000000-0',
      transitions: { 0: /\d/i },
    },
    placeholder: '0000-0000000-0',
    label: documentNumberFieldNames.umid,
    transform: identity,
  },
  PHIL_ID: {
    validators: {
      pattern: {
        value: /^\d{4}-\d{4}-\d{4}-\d{4}$/,
        message: NUMBER_FORMAT_ERROR,
      },
    },
    mask: {
      value: '0000-0000-0000-0000',
      transitions: { 0: /\d/i },
    },
    placeholder: '0000-0000-0000-0000',
    label: documentNumberFieldNames.philId,
    transform: identity,
  },
  PRC: {
    validators: {
      pattern: {
        value: /^\d{7}$/,
        message: NUMBER_FORMAT_ERROR,
      },
    },
    mask: {
      value: '0000000',
      transitions: { 0: /\d/i },
    },
    placeholder: '0000000',
    label: documentNumberFieldNames.prc,
    transform: identity,
  },
  DRIVING_LICENSE: {
    validators: {
      pattern: {
        value: /^[A-Z]\d{2}-\d{2}-\d{6}$/,
        message: NUMBER_FORMAT_ERROR,
      },
    },
    mask: {
      value: 'A00-00-000000',
      transitions: { 0: /\d/i, A: /[A-Za-z]/ },
    },
    placeholder: 'A00-00-000000',
    label: documentNumberFieldNames.drivingLicence,
    transform: (_event, { maskedValue }) => maskedValue.toUpperCase(),
  },
  PASSPORT: {
    validators: {
      pattern: {
        value: /^[A-Z]\d{7}[A-Z]$/,
        message: NUMBER_FORMAT_ERROR,
      },
    },
    mask: {
      value: 'A0000000A',
      transitions: { 0: /\d/i, A: /[A-Za-z]/ },
    },
    placeholder: 'A0000000B',
    label: documentNumberFieldNames.passport,
    transform: (_event, { maskedValue }) => maskedValue.toUpperCase(),
  },
  SSS: {
    validators: {
      pattern: {
        value: /^\d{2}-\d{7}-\d$/,
        message: NUMBER_FORMAT_ERROR,
      },
    },
    mask: {
      value: '00-0000000-0',
      transitions: { 0: /\d/i },
    },
    placeholder: '00-0000000-0',
    label: documentNumberFieldNames.sss,
    transform: identity,
  },
  POSTAL_ID: {
    validators: {
      pattern: {
        value: /^[\dA-ZÑ]{12}$/,
        message: NUMBER_FORMAT_ERROR,
      },
    },
    mask: {
      value: 'A00000000000',
      transitions: { 0: /[\dA-Za-zÑñ]/i, A: /[\dA-Za-zÑñ]/ },
    },
    placeholder: 'A00000000000',
    label: documentNumberFieldNames.postalId,
    transform: (_event, { maskedValue }) => maskedValue.toUpperCase(),
  },
};
