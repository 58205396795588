import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAnalytics } from '~app/providers/analytics-provider';
import { usePortalConfig } from '~app/providers/portal-config-provider';

import { SelectPos, ShowPosHelp } from '~features/select-pos';

import type { AttendanceStatus } from '~entities/attendance';
import {
  useAttachAttendancePhoto,
  useLogAttendance,
} from '~entities/attendance';
import { deviceLocation } from '~entities/device-metadata';
import type { Pos } from '~entities/pos';
import { selectedPosAtom } from '~entities/pos';

import { nonNullableValue } from '~shared/types/non-nullable-value';
import { BasicHeader } from '~shared/ui/basic-header';
import { Screen } from '~shared/ui/screen';

import Button, { LoadingButton } from '@breeze-platform-ui/button';
import { Col } from '@breeze-platform-ui/layout';
import { useNotifications } from '@pfa/front-notifications';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

type Props = {
  status: AttendanceStatus;
  onCancel: () => void;
  onSuccess: () => void;
};

export const LogAttendance = ({ status, onSuccess, onCancel }: Props) => {
  const { customerServicePhone } = usePortalConfig();

  const logAttendanceMutation = useLogAttendance();
  const attachPhotoMutation = useAttachAttendancePhoto();
  const attachRef = useRef<HTMLInputElement | null>(null);
  const notifications = useNotifications();

  const [selectedPos, setSelectedPos] = useAtom(selectedPosAtom);
  const [currentPos, setCurrentPos] = useState<Pos | null>(selectedPos);

  const { state: navigationState } = useLocation();
  const { openedFrom } = navigationState || {};
  const analytics = useAnalytics();

  const queryClient = useQueryClient();

  const handleAttachFileClick = () => {
    if (!currentPos) {
      notifications.error('Please, select store before taking selfie');
      return;
    }

    setSelectedPos(currentPos);
    openedFrom && analytics.trackPosChanged({ screen: openedFrom });
    queryClient.resetQueries({
      queryKey: ['agent-incentive'],
    });

    attachRef.current?.click();
  };

  const handleFileUpload = () => {
    const file = nonNullableValue(attachRef.current).files?.[0];
    if (!file) {
      notifications.error('Please, attach photo for attendance check');
      return;
    }

    // max file size is 5MB
    if (file.size > 5242880) {
      notifications.error(
        'Photo size should be under 5 MB. Please, reduce the photo resolution and upload it again'
      );
      return;
    }

    const geo = deviceLocation.getLocation();
    logAttendanceMutation.mutate(
      {
        type: status,
        posId: nonNullableValue(selectedPos).id,
        location: {
          latitude: geo.deviceLatitude,
          longitude: geo.deviceLongitude,
        },
      },
      {
        onSuccess: ({ uploadSelfieUrl }) => {
          if (file) {
            attachPhotoMutation.mutateAsync({
              uploadSelfieUrl,
              file,
            });
          }

          onSuccess();
        },
      }
    );
  };

  useEffect(() => {
    if (logAttendanceMutation.error) {
      notifications.error('Something went wrong. Please, try again later');
    }
  }, [logAttendanceMutation.error, notifications]);

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'white',
        zIndex: 100,
      }}
    >
      <Screen
        header={
          <BasicHeader
            mainContent="Select your store"
            secondaryContent={
              <ShowPosHelp customerServicePhone={customerServicePhone} />
            }
          />
        }
        footer={
          <Col alignCross="center" grow={1} gaps="xs">
            <>
              <LoadingButton
                wide
                size="m"
                onClick={handleAttachFileClick}
                loading={logAttendanceMutation.isPending}
              >
                Take a selfie to confirm
              </LoadingButton>
              <input
                type="file"
                accept="image/*"
                capture="user"
                ref={attachRef}
                hidden
                data-qa-type="upload-attendance-selfie"
                onChange={handleFileUpload}
              />
            </>
            <Button wide size="m" theme="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Col>
        }
      >
        <SelectPos currentPos={currentPos} onChange={setCurrentPos} />
      </Screen>
    </div>
  );
};
