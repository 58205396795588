import type { Application } from '~entities/application';

export const featureFlags: Pick<Application, 'featureFlags'> = {
  featureFlags: {
    skipValidation: true,
    scoringRules: {
      type: 'EXCLUDE',
      rules: [
        '57995034-02ae-433e-a130-2aa54169bbea',
        'cf46a816-f16b-4dab-97ef-73e87ba32deb',
        '7d438e43-567f-4115-8d3f-cc975f524462',
        '8d483d8c-4230-46ea-acb8-f9747b353fe3',
        '37ee77eb-1ab2-4c7c-95f0-1d628b9bd32d',
        'e1cc9a04-9412-4696-9423-09eb70c8fcb1',
        '4090289e-a969-46de-99be-12fc860d6f60',
        '9926c9eb-a1da-48dc-81b8-ec7ad1a7930b',
        'c862029e-d126-4991-a00d-4231b55bd0fd',
        'd2ccfc07-96f1-403d-bc46-d1f86367ceb5',
        'c2ad9489-1579-4362-8d34-e3acfcce3f28',
        '0b505d83-5514-4e0a-8d6f-8d1d96dd5f14',
        '3441a8d1-67bc-4951-8049-5761ed4eb8cd',
        'c6d56aa4-f8ff-4482-b44c-8ab69590b8a7',
        'c37c7526-1649-4de9-935b-21e0794671c5',
        '53121fb4-0eb9-4bb9-be20-bece222aeecc',
      ],
    },
    skipProcessActivities: {
      InvokeDuplicatesScoringStage1: {
        scoringResult: 'passed',
      },
      InvokeDuplicatesScoringStage2: {
        scoringResult: 'passed',
      },
      InvokeFreeScoringStrategy: {
        scoringResult: 'passed',
      },
      InvokePartnerScoringStrategy: {
        scoringResult: 'passed',
        underwritingCompleted: true,
      },
      PrepareForDeviceScoring: {
        scoringResult: 'passed',
      },
      InvokeUnderwritingScoringStrategy: {
        scoringResult: 'passed',
      },
      FindAuthSelfieInMdm: {
        authSelfieFaceId: '01GPWJGPETYJ0B0037DDSD1NK7',
      },
    },
    scoringAbTests: [
      {
        name: 'uw_old_pos_20231101',
        branch: 'UW_OLD_POS_20231101_NO',
      },
    ],
  },
};
