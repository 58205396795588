import { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import type { ClientInitiationNavigationState } from '~pages/client-initiation';

import { FormLoadingPage } from '~widgets/form-loading-page';
import { formatLoanCalculator } from '~widgets/loan-calculator';

import { ActiveApplicationWarning } from '~features/create-application-result';

import type { ApplicationLoanDetails } from '~entities/application';
import { useGetApplicationQuery } from '~entities/application';
import { useGetPersonQuery } from '~entities/person';

import Text from '@breeze-platform-ui/text';
import * as Sentry from '@breeze/web-lib-sentry';

import { LoanApplicationForm } from './form/loan-application-form';

import {
  useApplicationPageNavigationConstraints,
  recoverApplicationScreenValues,
  recoverPersonScreenValues,
} from '../lib';

export const LoanApplicationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const analytics = useCustomerAnalytics();
  const { isLoading: isApplicationLoading, data: applicationData } =
    useGetApplicationQuery();
  const { isLoading: isPersonLoading, data: personData } = useGetPersonQuery();
  const isFormLoading = isApplicationLoading || isPersonLoading;
  const formRenderTimeRef = useRef<Partial<{ start: number; end: number }>>({});

  useApplicationPageNavigationConstraints();

  useEffect(() => {
    if (formRenderTimeRef.current.end) {
      return;
    }

    if (isFormLoading && !formRenderTimeRef.current.start) {
      formRenderTimeRef.current.start = +new Date();
      return;
    }

    formRenderTimeRef.current.end = +new Date();
    const formRenderTime =
      formRenderTimeRef.current.end -
      (formRenderTimeRef.current.start as number);
    Sentry.setMeasurement(
      'ui.applicationForm.render',
      formRenderTime / 1000,
      'second'
    );
  }, [isFormLoading]);

  if (isFormLoading) {
    return <FormLoadingPage />;
  }

  if (applicationData) {
    if (
      applicationData.applicationStatus !== 'NEW' ||
      applicationData.formFillingStatus === 'FINISHED'
    ) {
      return <ActiveApplicationWarning applicationId={applicationData.id} />;
    }

    const recoveredApplication =
      recoverApplicationScreenValues(applicationData);

    const { loanCalculator, promoterCode } =
      (location.state as ClientInitiationNavigationState) || {};

    let applicationLoanDetails: ApplicationLoanDetails = {
      orderItems: applicationData.orderItems,
      requestedProduct: {
        type: 'POS_LOAN',
        ...applicationData.requestedProduct,
      },
      offer: applicationData.offer,
      promoCode: applicationData.promoCode,
    };

    if (loanCalculator) {
      applicationLoanDetails = formatLoanCalculator(loanCalculator);
    }

    const shouldChangeToAgentPortalFlow =
      applicationData.selfApp && applicationData.selfAppFlowType === 'MOBILE';

    return (
      <LoanApplicationForm
        clientPhone={applicationData.person.mobilePhone}
        applicationLoanDetails={applicationLoanDetails}
        applicationId={applicationData.id}
        shouldChangeToAgentPortalFlow={shouldChangeToAgentPortalFlow}
        initialValues={{
          ...recoverPersonScreenValues(personData || {}),
          ...recoveredApplication,
        }}
        promoterCode={promoterCode || applicationData.metaData?.promoterCode}
        onSubmit={() => {
          analytics.trackApplicationSubmitted({
            applicationId: applicationData.id,
          });
          navigate(`/applications/${applicationData.id}`, { replace: true });
        }}
      />
    );
  }
  return <Text>Application creation error</Text>;
};
