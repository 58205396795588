import type { Validate } from 'react-hook-form';

export function combineValidators<T = string, TFormValues = unknown>(
  ...validators: Validate<T, TFormValues>[]
): Validate<T, TFormValues> {
  return function combinedValidator(value: T, formValues: TFormValues) {
    for (const validator of validators) {
      const result = validator(value, formValues);

      if (result === false || typeof result === 'string') {
        return result;
      }
    }

    return undefined;
  };
}
