import type { FieldErrors, FieldValues } from 'react-hook-form';

import isEmpty from '@tinkoff/utils/is/empty';

/**
 * TODO: add support for nested forms and form arrays
 */
export function getValidValues<TFieldValues extends FieldValues = FieldValues>(
  formValues: Partial<TFieldValues>,
  formErrors: Partial<FieldErrors<TFieldValues>>
): Partial<TFieldValues> {
  return Object.entries(formValues).reduce<Partial<TFieldValues>>(
    (result, [key, value]) => {
      if (!formErrors[key] && !isEmpty(value)) {
        result[key as keyof TFieldValues] = value;
      }

      return result;
    },
    {}
  );
}
