import type { Validate } from 'react-hook-form';

import { endOfDay, format, isBefore, parse } from 'date-fns';

export function minDate<TFormValues>(options: {
  minDate: Date;
  message?: string;
}): Validate<string | undefined, TFormValues> {
  return function isDateAfterMinDateValidator(value: string | undefined) {
    const dateValue = value && parse(value, 'MM/dd/yyyy', new Date());

    if (
      !dateValue ||
      !isBefore(endOfDay(dateValue), endOfDay(options.minDate))
    ) {
      return undefined;
    }

    return (
      options.message ??
      `Enter the date after ${format(options.minDate, 'MM/dd/yyyy')}`
    );
  };
}
