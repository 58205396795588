import { useDi } from '~app/providers/di-provider';

import type { Enabled, UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type {
  AgentIncentive,
  AgentIncentiveMonthHistory,
} from '../agent-incentive';

export const useGetAgentIncentiveQuery = (options?: {
  enabled: Enabled<AgentIncentive, Error>;
}) => {
  const { agentIncentiveApi } = useDi();

  return useQuery({
    queryKey: ['agent-incentive'],
    queryFn: () => agentIncentiveApi.getAgentIncentive(),
    gcTime: 2,
    ...options,
  });
};

export const useGetAgentIncentiveHistoryQuery = (
  options?: UseQueryOptions<AgentIncentiveMonthHistory[], Error>
) => {
  const { agentIncentiveApi } = useDi();

  return useQuery({
    queryKey: ['agent-incentive-history'],
    queryFn: () => agentIncentiveApi.getAgentIncentiveHistory(),
    gcTime: 2,
    ...options,
  });
};
