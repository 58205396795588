import React from 'react';

import styles from './warning.module.css';

type Props = {
  icon: string | React.ReactNode;
  title: React.ReactNode;
  description?: React.ReactNode;
  footerContent?: React.ReactNode;
};

export const Warning = ({ icon, title, description, footerContent }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        {typeof icon === 'string' ? (
          <img src={icon} width="180" height="180" alt="warning" />
        ) : (
          icon
        )}
      </div>
      <h4 className={styles.title}>{title}</h4>
      {description && <p className={styles.description}>{description}</p>}
      {footerContent && <div className={styles.footer}>{footerContent}</div>}
    </div>
  );
};
