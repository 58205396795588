import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { AttendanceStatusResponse } from './attendance-api';
import { attendanceKeys } from './attendance-keys';
import { gatewayAttendanceApi } from './gateway-attendance-api';

export const useAttendanceStatus = (
  props?: Pick<UseQueryOptions, 'enabled'>
) => {
  return useQuery({
    queryKey: attendanceKeys.status(),
    queryFn: async () => gatewayAttendanceApi.getAttendanceStatus(),
    retry: 3,
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: true,
    ...props,
  } as UseQueryOptions<AttendanceStatusResponse>);
};
