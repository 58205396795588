import {
  AttendanceStatus,
  BreakIcon,
  CommuteIcon,
  isBreakStatus,
  LunchIcon,
  TimeOutIcon,
} from '~entities/attendance';
import {
  getMatchingWorkingStatus,
  isWorkingStatus,
} from '~entities/attendance';

import Button from '@breeze-platform-ui/button';
import Text from '@breeze-platform-ui/text';

import styles from './attendance-actions.module.css';

import { ActionButton } from '../action-button/action-button';
import { ReturnToWorkAction } from '../return-to-work-action/return-to-work-action';

export const AttendanceActions = ({
  status = AttendanceStatus.CheckOut,
  onLogAttendance,
}: {
  status?: AttendanceStatus;
  onLogAttendance: (status: AttendanceStatus) => void;
}) => {
  if (isWorkingStatus(status)) {
    return (
      <div
        className={styles.actionsContainer}
        data-qa-type="attendance-break-actions"
      >
        <ActionButton
          dataQaType="attendance-log-break-button"
          Icon={BreakIcon}
          label="Take a break"
          onClick={() => onLogAttendance(AttendanceStatus.BreakIn)}
        />
        <ActionButton
          dataQaType="attendance-log-lunch-button"
          Icon={LunchIcon}
          label="Go to lunch"
          onClick={() => onLogAttendance(AttendanceStatus.LunchIn)}
        />
        <ActionButton
          dataQaType="attendance-log-commute-button"
          Icon={CommuteIcon}
          label="Commute"
          onClick={() => onLogAttendance(AttendanceStatus.CommuteIn)}
        />
        <ActionButton
          dataQaType="attendance-log-time-out-button"
          Icon={TimeOutIcon}
          label="Time out"
          onClick={() => onLogAttendance(AttendanceStatus.CheckOut)}
        />
      </div>
    );
  }

  if (isBreakStatus(status)) {
    const nextWorkingStatus = getMatchingWorkingStatus(status);

    return (
      <ReturnToWorkAction
        status={status}
        onClick={() => {
          onLogAttendance(nextWorkingStatus);
        }}
      />
    );
  }

  return (
    <Button
      wide
      size="l"
      onClick={() => {
        onLogAttendance(AttendanceStatus.CheckIn);
      }}
    >
      <Text bold size={15}>
        Time in
      </Text>
    </Button>
  );
};
