import { useAnalytics } from '~app/providers/analytics-provider';

import { type ProductParams } from '~entities/product';

import { Hint } from '@breeze-platform-ui/tooltip';

const RangeComponent = ({
  min,
  max,
  children,
}: React.PropsWithChildren<{
  min: number;
  max: number;
}>) => (min === max ? null : <>{children}</>);

export const TermsHint = ({
  minMonthlyInterestRate,
  maxMonthlyInterestRate,
  minOriginationFeeFlat,
  maxOriginationFeeFlat,
  minOriginationFeeRate,
  maxOriginationFeeRate,
}: ProductParams) => {
  const analytics = useAnalytics();

  return (
    <span data-qa-type="loan-terms-tooltip">
      All fees included&nbsp;
      <Hint
        direction="top"
        theme="dark"
        onClick={() => analytics.trackLoanTermsHintClick()}
        popoverContent={
          <>
            <p>
              {minMonthlyInterestRate}%
              <RangeComponent
                min={minMonthlyInterestRate}
                max={maxMonthlyInterestRate}
              >
                &nbsp;–&nbsp;{maxMonthlyInterestRate}%
              </RangeComponent>
              &nbsp;interest rate
            </p>
            <p>
              + ₱{minOriginationFeeFlat}
              <RangeComponent
                min={minOriginationFeeFlat}
                max={maxOriginationFeeFlat}
              >
                &nbsp;–&nbsp;₱{maxOriginationFeeFlat}
              </RangeComponent>
              &nbsp;processing fee
            </p>
            <p>
              + {minOriginationFeeRate}%
              <RangeComponent
                min={minOriginationFeeRate}
                max={maxOriginationFeeRate}
              >
                &nbsp;–&nbsp;{maxOriginationFeeRate}%
              </RangeComponent>
              &nbsp;of the loan amount
            </p>
          </>
        }
      />
    </span>
  );
};
