import type { FieldValues } from 'react-hook-form';

import { isSuggestion } from '~shared/types/suggestion';

import isEmpty from '@tinkoff/utils/is/empty';

import type { SuggestionField } from '../api';

export function getInitialAddressValues(
  initialFormValue: FieldValues,
  fieldNamesMap: Record<string, SuggestionField>
): Record<string, string> {
  if (isEmpty(initialFormValue)) {
    return {};
  }

  return Object.keys(fieldNamesMap).reduce(
    (result, key) => {
      const fieldValue = initialFormValue[key] ?? null;

      result[fieldNamesMap[key]] = isSuggestion(fieldValue)
        ? fieldValue.value
        : fieldValue;

      return result;
    },
    {} as Record<string, string>
  );
}
