import { useEffect, useState } from 'react';

import { useOnMountEffect } from '~shared/hooks';

import isNil from '@tinkoff/utils/is/nil';

import { ButtonLink } from '@breeze-platform-ui/button';
import Text from '@breeze-platform-ui/text';
import { useMachine } from '@xstate/react';

import {
  getOtpRequestDelaySeconds,
  setOtpRequestTime,
  type OTP_TYPE,
  useResendCountdown,
  isAttemptsLimitReached,
  getAttemptsLimitMessage,
  getRequestDelayTime,
} from '../../../lib';
import { resendingStatesMachine } from '../../../model';

interface Props {
  confirmationType: OTP_TYPE;
  phoneNumber: string;
  disabled?: boolean;
  isAuthorizationError: (error: Error) => boolean;
  onResend: () => Promise<void>;
  onAuthError: () => void;
}

export const ResendButton = (props: Props) => {
  const {
    phoneNumber,
    confirmationType,
    disabled,
    isAuthorizationError,
    onResend,
    onAuthError,
  } = props;
  const [timer, startCountdown] = useResendCountdown();
  const [isAttemptsLimitError, setAttemptsLimitError] = useState(false);

  useOnMountEffect(() => {
    const delay = getOtpRequestDelaySeconds(confirmationType, phoneNumber);

    if (delay) {
      startCountdown(delay);
    }
  });

  useEffect(() => {
    if (isAttemptsLimitReached(confirmationType, phoneNumber)) {
      setAttemptsLimitError(true);
    } else {
      setAttemptsLimitError(false);
    }
  }, [timer, confirmationType, phoneNumber]);

  const [state, send] = useMachine(resendingStatesMachine, {
    services: {
      resend: () => {
        setOtpRequestTime(confirmationType, phoneNumber, new Date().getTime());

        return onResend();
      },
    },
    actions: {
      startCountdown: () =>
        startCountdown(
          getOtpRequestDelaySeconds(confirmationType, phoneNumber)
        ),
    },
    guards: {
      isAuthorizationError: ({ error }) =>
        !isNil(error) && isAuthorizationError(error),
    },
  });
  const resendDisabled =
    state.matches('loading') || disabled || (!!timer && isAttemptsLimitError);

  useEffect(() => {
    if (state.matches('authorizationError')) {
      onAuthError();
    }
  }, [state, onAuthError]);

  useEffect(() => {
    if (state.matches('countdown') && timer === 0) {
      send({ type: 'COUNTDOWN_OVER' });
    }
  }, [state, timer, send]);

  const showAttemptsLimitTimer = timer > 0 && isAttemptsLimitError;
  const showResendTimer = timer > 0 && !isAttemptsLimitError;

  return (
    <>
      {showAttemptsLimitTimer && (
        <Text color="rgb(227, 28, 28)">{getAttemptsLimitMessage(timer)}</Text>
      )}
      {showResendTimer && (
        <Text color="rgba(0, 0, 0, 0.4)" size={13}>
          Request a&nbsp;new code in&nbsp;{getRequestDelayTime(timer)}
        </Text>
      )}
      {!timer && (
        <ButtonLink
          size="s"
          disableHorizontalPadding
          disableVerticalPadding
          disabled={resendDisabled}
          onClick={() => {
            send({ type: 'RESEND_CODE' });
          }}
        >
          Request a&nbsp;new code via&nbsp;SMS
        </ButtonLink>
      )}
      {state.matches('unexpectedError') && (
        <Text color="#e31c1c" size={13}>
          Error receiving the&nbsp;code
        </Text>
      )}
    </>
  );
};
