import { Controller, useFormContext } from 'react-hook-form';

import { yesNoOptions } from '~shared/constants/yes-no-options';
import { combineValidators } from '~shared/lib/combine-validators';
import { SectionTitle } from '~shared/ui/section-title';
import { validators } from '~shared/validators';

import { Input, Select } from '@breeze/rhf-adapters';

import {
  COMPANY_NAME_REQUIRED_ERROR,
  SELECT_REQUIRED_ERROR,
} from '../../constants/errors';
import { COMPANY_NAME_FIELD_NAME } from '../../constants/field-names';
import type { EmployedWithCompanyStatusValue } from '../../lib';

type Props = Readonly<{
  employmentStatus: EmployedWithCompanyStatusValue;
}>;

export const CompanyDetails = ({ employmentStatus }: Props) => {
  const { control } = useFormContext();
  const isBusinessOwner = employmentStatus === 'OWN_BUSINESS';
  const sectionTitle = isBusinessOwner ? 'Business details' : 'Company details';

  return (
    <>
      <SectionTitle title={sectionTitle} margin="0 0 16px 0" />
      <Controller
        name="companyName"
        control={control}
        rules={{
          validate: combineValidators(
            validators.required({ text: COMPANY_NAME_REQUIRED_ERROR }),
            validators.maxLength({ maxLength: 150 }),
            validators.filipinoCompany()
          ),
        }}
        render={(fieldProps) => (
          <Input {...fieldProps} label={COMPANY_NAME_FIELD_NAME} />
        )}
      />
      {isBusinessOwner && (
        <Controller
          name="haveEmployees"
          control={control}
          rules={{
            required: SELECT_REQUIRED_ERROR,
          }}
          render={(fieldProps) => (
            <Select
              {...fieldProps}
              label="Does the&nbsp;customer employ people?"
              options={yesNoOptions}
            />
          )}
        />
      )}
    </>
  );
};
