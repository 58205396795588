import { useNavigate } from 'react-router-dom';

import { MobileSelfAppRedirectPage } from '~pages/mobile-self-app-redirect';

import {
  ClientConfirmationGuardHOC,
  ActiveApplicationGuardHOC,
} from '~features/auth/guard';
import { CheckAttendanceGuardHOC } from '~features/check-attendance';
import { SelectedPosGuardHOC } from '~features/select-pos';

export default function MobileSelfApRedirect() {
  const navigate = useNavigate();
  return (
    <CheckAttendanceGuardHOC onCancel={() => navigate('/applications')}>
      <SelectedPosGuardHOC>
        <ClientConfirmationGuardHOC>
          <ActiveApplicationGuardHOC
            onAccessDenied={() => navigate('/applications')}
          >
            <MobileSelfAppRedirectPage />
          </ActiveApplicationGuardHOC>
        </ClientConfirmationGuardHOC>
      </SelectedPosGuardHOC>
    </CheckAttendanceGuardHOC>
  );
}
