import type { Validate } from 'react-hook-form';

import isEmpty from '@tinkoff/utils/is/empty';
import isString from '@tinkoff/utils/is/string';

import { addYears, compareAsc } from 'date-fns';

const parseDate = (value: string): Date => {
  const [month, day, year] = value
    .split('/')
    .map((datePart) => parseInt(datePart, 10));

  // Set date to make function independent of the current date
  const date = new Date(1960, 0, 1, 0, 0, 0, 0);

  date.setFullYear(year);
  date.setMonth(month);
  date.setDate(day);

  return date;
};

interface Options {
  text?: string;
  minAge: number;
  maxAge: number;
}

export function ageRange<TFormValues>(
  options: Options
): Validate<unknown, TFormValues> {
  return function ageRangeValidator(value) {
    const {
      minAge = 0,
      maxAge = 0,
      text = 'Enter a valid date of birth',
    } = options ?? {};

    if (isEmpty(value) || !isString(value)) {
      return undefined;
    }

    const now = new Date();
    const date = parseDate(value);

    let isValid = true;
    if (!(compareAsc(now, addYears(date, minAge)) >= 0)) {
      isValid = false;
    } else if (!(compareAsc(now, addYears(date, maxAge)) <= 0)) {
      isValid = false;
    }
    if (!isValid) {
      return text
        .replace(/{minAge}/g, minAge.toString())
        .replace(/{maxAge}/g, maxAge.toString());
    }
  };
}
