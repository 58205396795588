import { useMutation, useQueryClient } from '@tanstack/react-query';

import { gatewaySocialLeadsApi } from './gateway-social-leads-api';
import { fetchSocialLead } from './get-social-leads';
import type { UpdateConversationDetailsPayload } from './social-leads-api';
import { socialLeadsKeys } from './social-leads-keys';

import type { SocialLead, NewSocialLead } from '../social-lead';

export function useSubmitSocialLead() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: NewSocialLead) =>
      gatewaySocialLeadsApi.submit(payload),
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({ queryKey: socialLeadsKeys.list() });

      queryClient.prefetchQuery({
        queryKey: socialLeadsKeys.lead(id),
        queryFn: () => fetchSocialLead(id),
      });
    },
  });
}

export function useUpdateConversationDetails() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: UpdateConversationDetailsPayload) =>
      gatewaySocialLeadsApi.updateConversationDetails(payload),
    onSuccess: async (_data, payload) => {
      const key = socialLeadsKeys.lead(payload.id);
      const prevValue = queryClient.getQueryData<SocialLead>(key);

      if (prevValue) {
        await queryClient.setQueryData(key, {
          ...prevValue,
          conversationDetails: {
            ...prevValue.conversationDetails,
            comment: payload.comment,
          },
        });
      }
    },
  });
}
