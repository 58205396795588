import { environment } from '~app/environment';

import { getCookie, setCookie, deleteCookie } from '~shared/lib/cookies';

import { createInstance } from '@amplitude/analytics-browser';

import type {
  AmplitudeCustomerAnalytics,
  AmplitudeCustomerAnalyticsPayload,
  AmplitudeCalculatorInfoPayload,
  BarcodeRecognitionEvent,
} from '../types';

const amplitude = createInstance();
const CLIENT_ANALYTICS_ID_COOKIE = 'client_analytics_id';
export const EMPLOYEE_ID_COOKIE = 'employee_id';

const getAnalytics = (): AmplitudeCustomerAnalytics => {
  amplitude?.init(environment.AMPLITUDE_ANALYTICS_BY_APPLICATION_KEY, {
    defaultTracking: false,
    appVersion: environment.VERSION,
  });

  let stringifiedLastTrackedEvent: string | undefined;

  function setUserId(applicationId?: string) {
    amplitude?.setUserId(applicationId);
  }

  function track(
    event: string,
    payload: AmplitudeCustomerAnalyticsPayload | AmplitudeCalculatorInfoPayload
  ) {
    amplitude?.setUserId(payload.applicationId);
    amplitude?.track(event, {
      ...payload,
      employeeId: getCookie(EMPLOYEE_ID_COOKIE),
      client_analytics_id: getCookie(CLIENT_ANALYTICS_ID_COOKIE),
    });
  }

  // this method is to prevent events duplication that are fired on component mount (if some component is mounted more than one time)
  function trackOnce(
    event: string,
    payload: AmplitudeCustomerAnalyticsPayload
  ) {
    const stringifiedEvent = JSON.stringify({ event, payload: payload || {} });
    if (stringifiedLastTrackedEvent !== stringifiedEvent) {
      stringifiedLastTrackedEvent = stringifiedEvent;
      track(event, payload);
    }
  }
  function trackForceOfferApplied(
    payload: AmplitudeCustomerAnalyticsPayload & { offerId: string }
  ) {
    track('forced_offer_applied', payload);
  }

  function trackApplicationSubmitted(
    payload: AmplitudeCustomerAnalyticsPayload
  ) {
    track('application_submitted', payload);
  }

  function trackFormFillingStarted(payload: AmplitudeCustomerAnalyticsPayload) {
    track('filling_application_started', payload);
  }

  function trackFormBackClick(payload: AmplitudeCustomerAnalyticsPayload) {
    track('back_was_used', payload);
  }

  function trackAddressCheckboxSwitchedOff(
    payload: AmplitudeCustomerAnalyticsPayload
  ) {
    track('address_checkbox_switched_off', payload);
  }

  function trackSignAgreementButtonTap(
    payload: AmplitudeCustomerAnalyticsPayload
  ) {
    track('agreement_start_signing', payload);
  }

  function trackAgreementShown(payload: AmplitudeCustomerAnalyticsPayload) {
    track('agreement_shown', payload);
  }

  function trackAgreementRenderSuccess(
    payload: AmplitudeCustomerAnalyticsPayload
  ) {
    track('agreement_render_success', payload);
  }

  function trackAgreementRenderError(
    payload: AmplitudeCustomerAnalyticsPayload
  ) {
    track('agreement_render_error', payload);
  }

  function trackAgreementFallbackShown(
    payload: AmplitudeCustomerAnalyticsPayload
  ) {
    track('agreement_fallback_shown', payload);
  }

  function trackAgreementDownloadClicked(
    payload: AmplitudeCustomerAnalyticsPayload
  ) {
    track('agreement_download_clicked', payload);
  }

  function trackApplicationFromListShown(
    payload: AmplitudeCustomerAnalyticsPayload
  ) {
    track('app_from_application_list', payload);
  }

  function trackApprovedScreenShown(
    payload: AmplitudeCustomerAnalyticsPayload
  ) {
    track('application_approved', payload);
  }

  function trackFinishedScreenShown(
    payload: AmplitudeCustomerAnalyticsPayload
  ) {
    track('application_finished', payload);
  }

  function trackBarcodeRecognitionEvent(
    event: BarcodeRecognitionEvent,
    payload: AmplitudeCustomerAnalyticsPayload
  ) {
    track(`barcode_recognition_${event}`, payload);
  }

  function trackOcrSuccess(payload: AmplitudeCustomerAnalyticsPayload) {
    track('ocr_result_success', payload);
  }

  function trackOcrError(payload: AmplitudeCustomerAnalyticsPayload) {
    track('ocr_result_error', payload);
  }

  function trackOcrResultModified(payload: AmplitudeCustomerAnalyticsPayload) {
    track('ocr_result_modified', payload);
  }

  function trackPreApprovedQrScanned(
    payload: AmplitudeCustomerAnalyticsPayload
  ) {
    track('self_app_pre_approved_qr_scanned', payload);
  }

  function trackPreApprovedCalculatorUpdate(
    payload: AmplitudeCalculatorInfoPayload
  ) {
    track('self_app_pre_approved_calculator_update', payload);
  }

  function trackShortFormFillingStarted(
    payload: AmplitudeCustomerAnalyticsPayload
  ) {
    track('self_app_short_form_filling_started', payload);
  }

  function trackShortFormSubmitted(payload: AmplitudeCustomerAnalyticsPayload) {
    track('self_app_short_form_submitted', payload);
  }

  function trackContinueInMobileAppShown(
    payload: AmplitudeCustomerAnalyticsPayload
  ) {
    track('self_app_continue_in_mobile_app_shown', payload);
  }

  function trackSwitchToAgentFlow(payload: AmplitudeCustomerAnalyticsPayload) {
    track('self_app_switched_to_agent_flow', payload);
  }

  function setClientAnalyticsId(analyticsId?: string) {
    analyticsId
      ? setCookie({
          name: CLIENT_ANALYTICS_ID_COOKIE,
          value: analyticsId,
        })
      : deleteCookie(CLIENT_ANALYTICS_ID_COOKIE);
  }

  function setEmployeeId(employeeId?: string) {
    employeeId
      ? setCookie({
          name: EMPLOYEE_ID_COOKIE,
          value: employeeId,
        })
      : deleteCookie(EMPLOYEE_ID_COOKIE);
  }

  return {
    setUserId,
    setClientAnalyticsId,
    setEmployeeId,
    track,
    trackOnce,
    trackForceOfferApplied,
    trackApplicationSubmitted,
    trackFormFillingStarted,
    trackFormBackClick,
    trackAddressCheckboxSwitchedOff,
    trackSignAgreementButtonTap,
    trackAgreementShown,
    trackAgreementRenderSuccess,
    trackAgreementRenderError,
    trackAgreementFallbackShown,
    trackAgreementDownloadClicked,
    trackApplicationFromListShown,
    trackApprovedScreenShown,
    trackFinishedScreenShown,
    trackOcrSuccess,
    trackOcrError,
    trackPreApprovedCalculatorUpdate,
    trackPreApprovedQrScanned,
    trackShortFormFillingStarted,
    trackShortFormSubmitted,
    trackContinueInMobileAppShown,
    trackSwitchToAgentFlow,
    trackOcrResultModified,
    trackBarcodeRecognitionEvent,
  };
};

export const amplitudeCustomerAnalytics = getAnalytics();
