import { useState } from 'react';

import { ContactLead } from '~features/contact-lead';
import { UpdateSocialLeadComment } from '~features/update-social-lead-comment';

import { useUpdateConversationDetails } from '~entities/social-lead';
import type { SocialLead } from '~entities/social-lead';

import { PopupCloseButton } from '~shared/ui/popup-close-button';

import { ButtonLink } from '@breeze-platform-ui/button';
import Button from '@breeze-platform-ui/button';
import Sheet from '@breeze-platform-ui/sheet';
import { useNotifications } from '@pfa/front-notifications';

import styles from './social-lead-details.module.css';

import { ConversationDetails } from '../conversation-details/conversation-details';

export const SocialLeadDetails = ({ lead }: { lead: SocialLead }) => {
  const [isEditPopupOpened, setIsEditPopupOpened] = useState(false);

  const updateConversationDetailsMutation = useUpdateConversationDetails();
  const notifications = useNotifications();

  const handleCloseEditComment = () => {
    setIsEditPopupOpened(false);
  };

  const handleOpenEditComment = () => {
    setIsEditPopupOpened(true);
  };

  const handleEditComment = (comment?: string) => {
    updateConversationDetailsMutation.mutate(
      { comment: comment ? comment.trim() : undefined, id: lead.id },
      {
        onSuccess: () => {
          handleCloseEditComment();
        },
        onError: () => {
          notifications.error(
            "Couldn't update the comment. Please, try again later"
          );
        },
      }
    );
  };

  return (
    <div className={styles.container}>
      <ContactLead {...lead} />
      <section className={styles.section}>
        <div className={styles.conversationDetailsHeader}>
          <h4 className={styles.sectionTitle}>Conversation details</h4>
        </div>
        <ConversationDetails lead={lead} />
      </section>
      <section className={styles.section}>
        {lead.conversationDetails.comment && (
          <>
            <div className={styles.commentHeader}>
              <h4 className={styles.sectionTitle}>Notes or comments</h4>
              <ButtonLink
                disableHorizontalPadding
                disableVerticalPadding
                size="m"
                onClick={handleOpenEditComment}
              >
                Edit
              </ButtonLink>
            </div>
            <p className={styles.comment}>{lead.conversationDetails.comment}</p>
          </>
        )}
        {!lead.conversationDetails.comment && (
          <Button size="m" wide onClick={handleOpenEditComment}>
            Add note or comment
          </Button>
        )}
      </section>
      <Sheet
        mode="normal"
        modes={{
          collapsed: false,
          normal: {
            minHeight: 298,
            maxHeight: 320,
          },
          expanded: false,
        }}
        opened={isEditPopupOpened}
        onClose={handleCloseEditComment}
      >
        <div className={styles.sheetHeader}>
          <h4 className={styles.sectionTitle}>Edit notes or comments</h4>
          <PopupCloseButton onClick={handleCloseEditComment} />
        </div>
        <UpdateSocialLeadComment
          initialComment={lead.conversationDetails.comment}
          isLoading={updateConversationDetailsMutation.isPending}
          onSave={handleEditComment}
        />
      </Sheet>
    </div>
  );
};
