import Button from '@breeze-platform-ui/button';
import { Col } from '@breeze-platform-ui/layout';

import styles from './success.module.css';
import successImg from './success.svg';

export const Success = ({
  onBack,
  onCreateNew,
}: {
  onBack: () => void;
  onCreateNew: () => void;
}) => {
  return (
    <div className={styles.container}>
      <img src={successImg} alt="" width={64} height={64} />

      <h1 className={styles.title}>Online&nbsp;customer form submitted!</h1>

      <Col alignCross="stretch" width="100%" gaps={16}>
        <Button wide onClick={onBack}>
          Ok
        </Button>
        <Button wide theme="flat" onClick={onCreateNew}>
          Submit another
        </Button>
      </Col>
    </div>
  );
};
