import { Loader } from '~shared/ui/loader/loader';

import Button from '@breeze-platform-ui/button';
import { Col } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';

import timeInImg from './images/time-in.webp';

import { useAttendanceStatus } from '../../api/get-attendance-status';
import { AttendanceStatus } from '../../attendance';

export const AttendanceWarning = ({
  onContinue,
  onCancel,
}: {
  onContinue: () => void;
  onCancel?: () => void;
}) => {
  const attendanceStatusQuery = useAttendanceStatus();
  const text =
    attendanceStatusQuery.data?.status === AttendanceStatus.CheckOut ? (
      <span>time&nbsp;in</span>
    ) : (
      <span>return to&nbsp;work</span>
    );

  if (!attendanceStatusQuery.isSuccess) {
    return <Loader centered />;
  }

  return (
    <Col alignCross="stretch" gaps={24} dataQaType="attendance-warning">
      <Col alignCross="center" gaps={8}>
        <img src={timeInImg} width={150} height={150} alt="" />
        <Text tagName="h2" size={24} bold align="center">
          To&nbsp;continue, you need to&nbsp;{text}
        </Text>
      </Col>
      <Col
        alignCross="stretch"
        gaps={12}
        margin="auto"
        width="100%"
        style={{ maxWidth: 400 }}
      >
        <Button wide size="l" onClick={onContinue}>
          Continue
        </Button>
        {onCancel && (
          <Button wide size="l" theme="secondary" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </Col>
    </Col>
  );
};
