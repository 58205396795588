import type { PropsWithChildren } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

import { AppLoadingPage } from '~widgets/app-loading-page';

import { isAuthorizedAtom, isEmployeeRoleAllowedAtom } from '~entities/auth';
import { isPosInitializedAtom, selectedPosAtom } from '~entities/pos';

import { useAtomValue } from 'jotai';

export const SelectedPosGuardHOC = ({ children }: PropsWithChildren) => {
  const selectedPos = useAtomValue(selectedPosAtom);
  const isAuthorized = useAtomValue(isAuthorizedAtom);
  const hasAccess = useAtomValue(isEmployeeRoleAllowedAtom);
  const navigate = useNavigate();
  const isAuthSuccess = !!isAuthorized && hasAccess;
  const { pathname } = useLocation();
  const isPosInitialized = useAtomValue(isPosInitializedAtom);

  if (isAuthSuccess && !selectedPos && isPosInitialized) {
    navigate(
      {
        pathname: '/pos-select',
        search: createSearchParams({
          redirectUrl: `${pathname}`,
          backUrl: '/account',
        }).toString(),
      },
      {
        replace: true,
      }
    );
  }

  if (!selectedPos) {
    return <AppLoadingPage />;
  }

  return <>{children}</>;
};
