import type { QueryClient } from '@tanstack/react-query';

import { applicationsKeys } from '../api';
import type {
  ApplicationDetails,
  ApplicationListQueryResponse,
} from '../application';

// TODO: update logic after applications store normalization

export const updateFinishedApplicationQueryData = (
  queryClient: QueryClient,
  applicationUpdate: Partial<ApplicationDetails>,
  applicationId: string,
  posId?: string
) => {
  const applications = queryClient.getQueryData<ApplicationListQueryResponse>(
    applicationsKeys.listByPos(posId)
  );
  const details = queryClient.getQueryData<ApplicationDetails>(
    applicationsKeys.application(applicationId)
  );
  if (details) {
    const updatedDetails = {
      ...details,
      ...applicationUpdate,
    };
    queryClient.setQueryData(
      applicationsKeys.application(applicationId),
      updatedDetails
    );
    if (applications) {
      queryClient.setQueryData<ApplicationListQueryResponse>(
        applicationsKeys.listByPos(posId),
        {
          pending: applications.pending.filter(
            ({ id }) => id !== applicationId
          ),
          list: applications.list.map((application) =>
            application.id === applicationId ? updatedDetails : application
          ),
        }
      );
    }
  }
};
