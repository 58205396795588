import { ApplicationsPage } from '~pages/applications';

import { SelectedPosGuardHOC } from '~features/select-pos';

import { EmptyScheduleGuard } from '~entities/shedule';

export default function Applications() {
  return (
    <EmptyScheduleGuard>
      <SelectedPosGuardHOC>
        <ApplicationsPage />
      </SelectedPosGuardHOC>
    </EmptyScheduleGuard>
  );
}
