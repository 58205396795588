import type { Validate } from 'react-hook-form';

import isEmpty from '@tinkoff/utils/is/empty';
import isString from '@tinkoff/utils/is/string';
import trim from '@tinkoff/utils/string/trim';

interface Options {
  text?: string;
  length?: number;
}

export function phoneLength<TFormValues>(
  options: Options = {}
): Validate<unknown, TFormValues> {
  return function phoneLengthValidator(value) {
    const { length = 11, text = 'Please enter a full number' } = options;

    if (!isString(value) || isEmpty(value)) {
      return undefined;
    }

    if (trim(value).length < length) {
      return text;
    }

    return undefined;
  };
}
