import { useQuery } from '@tanstack/react-query';

import { gatewayScheduleApi } from './gateway-schedule-api';

export function useGetDailySchedule(
  date: string,
  options?: { enabled?: boolean }
) {
  return useQuery({
    queryKey: ['schedule', date],
    queryFn: async () => {
      const response = await gatewayScheduleApi.getDailySchedule({ date });

      return response.schedule ?? null;
    },
    refetchOnWindowFocus: true,
    retry: 3,
    ...options,
  });
}
