import type { Validate } from 'react-hook-form';

import { format, isAfter, parse, startOfDay } from 'date-fns';

export function maxDate<TFormValues>(options: {
  maxDate: Date;
  message?: string;
}): Validate<string | undefined, TFormValues> {
  return function isDateBeforeMaxDateValidator(value: string | undefined) {
    const dateValue = value && parse(value, 'MM/dd/yyyy', new Date());

    if (
      !dateValue ||
      !isAfter(startOfDay(dateValue), startOfDay(options.maxDate))
    ) {
      return undefined;
    }

    return (
      options.message ??
      `Enter the date before ${format(options.maxDate, 'MM/dd/yyyy')}`
    );
  };
}
