import type { Validate } from 'react-hook-form';

import isEmpty from '@tinkoff/utils/is/empty';
import isString from '@tinkoff/utils/is/string';
import trim from '@tinkoff/utils/string/trim';

const pattern = /^[\s,()./'’\-a-zA-Z0-9]+$/;

interface Options {
  text: string;
}

export function latinText<TFormValues>(
  options?: Options
): Validate<unknown, TFormValues> {
  return function latinTextValidator(value) {
    const text =
      options?.text ??
      'Use only letters, numbers, spaces, or symbols , ( ) . / ’ -';

    if (isString(value) && !isEmpty(value) && !pattern.test(trim(value))) {
      return text;
    }

    return undefined;
  };
}
