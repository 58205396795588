import type { Validate } from 'react-hook-form';

import isEmpty from '@tinkoff/utils/is/empty';
import isString from '@tinkoff/utils/is/string';
import trim from '@tinkoff/utils/string/trim';

const pattern = /^[\s'’.\-a-zA-Z]+$/;

interface LatinOnlyOptions {
  text: string;
}

export function latinOnly<TFormValues>(
  options?: LatinOnlyOptions
): Validate<unknown, TFormValues> {
  return function latinOnlyValidator(value) {
    const text = options?.text ?? 'Use only letters and symbols ’ . -';

    if (isString(value) && !isEmpty(value) && !pattern.test(trim(value))) {
      return text;
    }

    return undefined;
  };
}
