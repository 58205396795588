import { useEffect } from 'react';

import { lastInteractionTimeAtom } from '~entities/auth';

import throttle from '@tinkoff/utils/function/throttle';

import { useSetAtom } from 'jotai';

// 1 minute
const USER_INTERACTION_THROTTLING_TIME = 60_000;
const userInteractEvents = [
  'click',
  'mousemove',
  'scroll',
  'touchstart',
  'touchend',
  'touchmove',
  'touchcancel',
];

export const useUserInteractionTracking = () => {
  const setLastInteractionTime = useSetAtom(lastInteractionTimeAtom);
  useEffect(() => {
    const subscriptions: (() => void)[] = [];
    userInteractEvents.forEach((event) => {
      const cb = throttle(USER_INTERACTION_THROTTLING_TIME, () => {
        setLastInteractionTime(Date.now());
      });

      window.addEventListener(event, cb, { passive: true });
      subscriptions.push(() => {
        window.removeEventListener(event, cb);
      });
    });
    return () => subscriptions.forEach((unsubscribeCb) => unsubscribeCb());
  }, [setLastInteractionTime]);
};
