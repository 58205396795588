import { useNavigate, useSearchParams } from 'react-router-dom';

import type { ExplanationForm } from '~widgets/missed-checkout-explanation';
import { MissedCheckoutExplanation } from '~widgets/missed-checkout-explanation';

import { useSendExplanatoryNote } from '~entities/attendance';

import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Screen } from '~shared/ui/screen/screen';

import { useNotifications } from '@pfa/front-notifications';

export const MissedCheckoutPage = () => {
  const explanatoryNoteMutation = useSendExplanatoryNote();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl') ?? '/account';
  const navigate = useNavigate();
  const notifications = useNotifications();

  const handleSendExplanation = (value: ExplanationForm) => {
    const [hours, minutes] = value.time.split(':');
    const date = new Date();
    date.setHours(+hours);
    date.setMinutes(+minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);

    explanatoryNoteMutation.mutate(
      {
        logRealTime: date.toISOString(),
        explanation: value.note.trim(),
      },
      {
        onSuccess: () => navigate(redirectUrl, { replace: true }),
        onError: () =>
          notifications.error('Something went wrong. Please, try again later'),
      }
    );
  };

  return (
    <Screen header={<HeaderWithLogo />}>
      <MissedCheckoutExplanation
        onDone={handleSendExplanation}
        isLoading={explanatoryNoteMutation.isPending}
      />
    </Screen>
  );
};
