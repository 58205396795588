import { useDi } from '~app/providers/di-provider';

import { isHttpError } from '~shared/errors';

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import type { SearchOfferByCodeResponse, SearchOfferResponse } from '../api';

export const useSearchOfferByPhoneMutation = (
  options?: UseMutationOptions<SearchOfferResponse, Error, string>
) => {
  const { offerApi } = useDi();
  return useMutation({
    mutationKey: ['search-offer-by-phone'],
    mutationFn: async (phone: string) => offerApi.getByPhone(phone),
    retry: 2,
    ...options,
  });
};

export const useSearchOfferByCodeMutation = (
  options?: UseMutationOptions<SearchOfferByCodeResponse, Error, string>
) => {
  const { offerApi } = useDi();

  return useMutation({
    mutationKey: ['search-offer-by-code'],
    mutationFn: async (code: string) => {
      try {
        const result = await offerApi.getByCode(code);
        return result;
      } catch (error: any) {
        if (isHttpError(error) && error.code === 'NOT_FOUND') {
          return { status: 'NOT_FOUND' } as SearchOfferByCodeResponse;
        }

        throw error;
      }
    },
    retry: 2,
    ...options,
  });
};
