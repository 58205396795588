import { useEffect, useState, useRef } from 'react';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import { logger } from '~entities/logger';

import { Int24Close } from '@breeze-platform-ui/iconsPack';

import styles from './barcode-detection.module.css';
import {
  DetectedBarcodeScreen,
  type DetectedData,
} from './detected-barcode-screen';
import type { DetectedBarcodeInfo } from './detected-barcode-screen';
import { ZoomButton } from './zoom-button';

import { getVideoToViewportRatio } from '../lib';

type Props = {
  formats?: BarcodeFormat[];
  applicationId: string;
  onClose: () => void;
  onDone: (result: { value: string; file: File }) => void;
};

export const BarcodeDetection = ({
  formats,
  onClose,
  onDone,
  applicationId,
}: Props) => {
  const customerAnalytics = useCustomerAnalytics();
  const trackRef = useRef<MediaStreamTrack>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const barcodeFrameRef = useRef<HTMLDivElement>(null);
  const imageCapture = useRef<ImageCapture>();
  const [zoomSupported, setZoomSupported] = useState(false);

  const [zoom, setZoom] = useState(1);
  const [barcodeFrameBox, setBarcodeFrameBox] = useState<DOMRect>();
  const [showMediaStream, setShowMediaStream] = useState(true);

  const [screenshot, setScreenshot] = useState<ImageBitmap>();
  const [detectedBarcode, setDetectedBarcode] = useState<DetectedBarcodeInfo>();

  const handleClose = () => {
    customerAnalytics.trackBarcodeRecognitionEvent('close', { applicationId });
    onClose();
  };

  const handleDone = (data: DetectedData) => {
    customerAnalytics.trackBarcodeRecognitionEvent('accept', { applicationId });
    onDone(data);
    onClose();
  };

  const handleRescan = () => {
    customerAnalytics.trackBarcodeRecognitionEvent('rescan', { applicationId });
    setScreenshot(undefined);
    setDetectedBarcode(undefined);
    setShowMediaStream(true);
    setZoom(1);
  };

  useEffect(() => {
    zoomSupported && trackRef.current?.applyConstraints({ zoom });
  }, [zoom, zoomSupported]);

  useEffect(() => {
    let unmounted = false;
    const video = videoRef.current;
    let mediaStream: MediaStream;
    if (showMediaStream) {
      window.navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: 'environment',
            zoom: true,
          },
        })
        .then((stream) => {
          if (unmounted) {
            stream?.getTracks().forEach((track) => {
              track.stop();
            });
            return;
          }
          if (video) {
            mediaStream = stream;
            video.srcObject = stream;
            video.playsInline = true;
            const track = stream.getVideoTracks()[0];
            trackRef.current = track;
            imageCapture.current = new ImageCapture(track);
            const settings = track.getSettings();
            video.onloadeddata = () => {
              if (video.readyState > 3) {
                if ('zoom' in settings) {
                  setZoomSupported(true);
                }
                video.play();
                setBarcodeFrameBox(
                  barcodeFrameRef.current?.getBoundingClientRect()
                );
              }
            };
          }
        })
        .catch((error) => {
          logger('barcode-detection').error(error);
        });
    }
    return () => {
      unmounted = true;
      mediaStream?.getTracks().forEach((track) => {
        track.stop();
      });
    };
  }, [showMediaStream]);

  useEffect(() => {
    const video = videoRef.current;

    const barcodeDetector = new BarcodeDetector({ formats });

    let timerId: NodeJS.Timeout;
    const detectBarcodes = () => {
      if (
        video &&
        barcodeFrameBox &&
        video.readyState >= HTMLMediaElement.HAVE_CURRENT_DATA
      ) {
        barcodeDetector
          .detect(video)
          .then((barcodes: DetectedBarcode[]) => {
            if (barcodes.length > 0) {
              const { left, top } = video.getBoundingClientRect();
              const { xRatio, yRatio } = getVideoToViewportRatio(video);
              let scaled: any;
              const barcode = barcodes.find((v) => {
                scaled = {
                  top: top + v.boundingBox.top / yRatio,
                  bottom: top + v.boundingBox.bottom / yRatio,
                  left: left + v.boundingBox.left / xRatio,
                  right: left + v.boundingBox.right / xRatio,
                };

                return (
                  scaled.top >= barcodeFrameBox.top &&
                  scaled.bottom <= barcodeFrameBox.bottom &&
                  scaled.left >= barcodeFrameBox.left &&
                  scaled.right <= barcodeFrameBox.right
                );
              });

              if (barcode) {
                imageCapture.current
                  ?.grabFrame()
                  .then((bitmap: ImageBitmap) => {
                    navigator.vibrate?.(300);
                    setShowMediaStream(false);
                    setZoomSupported(false);
                    setBarcodeFrameBox(undefined);
                    setScreenshot(bitmap);
                    setDetectedBarcode({
                      value: barcode.rawValue,
                      coordinates: scaled,
                    });
                  });
              }
            }
            timerId = setTimeout(() => detectBarcodes(), 10);
          })
          .catch((error) => {
            logger('barcode-detection').error(error);
          });
      }
    };

    if (barcodeFrameBox) {
      detectBarcodes();
    }
    return () => timerId && clearTimeout(timerId);
  }, [barcodeFrameBox, formats]);

  return (
    <>
      <button
        type="button"
        onClick={handleClose}
        className={styles.closeButton}
      >
        <Int24Close cursor="pointer" />
      </button>
      <DetectedBarcodeScreen
        screenshot={screenshot}
        detectedBarcodeInfo={detectedBarcode}
        onRescan={handleRescan}
        onDone={handleDone}
      />
      {showMediaStream && (
        <div className={styles.container}>
          <div ref={barcodeFrameRef} className={styles.detectorFrame} />
          <p className={styles.hintText}>Place the barcode into the frame</p>
          {zoomSupported && (
            <div className={styles.zoomButtonsContainer}>
              <ZoomButton
                value={1}
                applicationId={applicationId}
                isApplied={zoom === 1}
                onClick={() => setZoom(1)}
              />
              <ZoomButton
                value={2}
                applicationId={applicationId}
                isApplied={zoom === 2}
                onClick={() => setZoom(2)}
              />
            </div>
          )}

          <video muted className={styles.detectorVideo} ref={videoRef} />
        </div>
      )}
    </>
  );
};
