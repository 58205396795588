import { FormHeader } from '~shared/ui/form-header';
import { Loader } from '~shared/ui/loader';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

interface Props {
  headerText?: string;
  title?: string;
}

export const FormLoadingPage = ({ headerText = '', title = '' }: Props) => {
  return (
    <Screen header={<FormHeader href="/" text={headerText} />}>
      <ScreenTitle title={title} />
      <Loader />
    </Screen>
  );
};
