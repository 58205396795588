import { useRef } from 'react';

import type { MutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

export function useQueuedMutation<T>(mutation: MutationOptions<any, any, T>) {
  const latestPayload = useRef<T>();
  const queuedMutateAsyncControls = useRef<{ resolve: any; reject: any }>();
  const {
    mutate: originalMutate,
    mutateAsync: originalMutateAsync,
    isPending,
    isError,
    isIdle,
    isSuccess,
    isPaused,
  } = useMutation(mutation);
  const isAsyncMutateInQueue = () => queuedMutateAsyncControls.current;
  const handleMutateAsync = (payload: T) => {
    return originalMutateAsync(payload)
      .then((res) => {
        queuedMutateAsyncControls.current?.resolve(res);
      })
      .catch((err) => {
        queuedMutateAsyncControls.current?.reject(err);
      })
      .finally(() => {
        queuedMutateAsyncControls.current = undefined;
      });
  };
  const mutate = (payload: T) => {
    if (isPending || isAsyncMutateInQueue()) {
      latestPayload.current = payload;
    } else {
      originalMutate(payload, {
        onSettled: () => {
          const next = latestPayload.current;
          if (next) {
            latestPayload.current = undefined;
            if (isAsyncMutateInQueue()) {
              return handleMutateAsync(next);
            }
            originalMutate(next);
          }
        },
      });
    }
  };
  const finalMutateAsync = (payload: T) => {
    const lastPayload = latestPayload.current;
    latestPayload.current = payload;
    if (isAsyncMutateInQueue()) {
      // No queue for mutate async. Make next request after finishing the previous if required (error on submit).
      return Promise.resolve();
    }
    if (isPending || typeof lastPayload !== 'undefined') {
      // mutation is in progress, put into the queue
      return new Promise((resolve, reject) => {
        queuedMutateAsyncControls.current = { resolve, reject };
      });
    }
    return new Promise((resolve, reject) => {
      queuedMutateAsyncControls.current = { resolve, reject };
      return handleMutateAsync(payload);
    });
  };
  return { mutate, finalMutateAsync, isError, isIdle, isSuccess, isPaused };
}
