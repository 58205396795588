import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import type { OnlineCustomerFormValues } from '~features/create-online-customer';
import { CreateOnlineCustomer } from '~features/create-online-customer';

import { useSubmitSocialLead } from '~entities/social-lead';
import type { NewSocialLead } from '~entities/social-lead';

import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { NavBackButton } from '~shared/ui/nav-back-button';
import { Screen } from '~shared/ui/screen';

import { LoadingButton } from '@breeze-platform-ui/button';
import { useNotifications } from '@pfa/front-notifications';
import { endOfDay, parse, startOfDay } from 'date-fns';

import { Success } from './ui/success/success';

export const NewSocialLeadPage = () => {
  const methods = useForm<OnlineCustomerFormValues>({ mode: 'all' });
  const submitSocialLeadMutation = useSubmitSocialLead();
  const navigate = useNavigate();
  const notifications = useNotifications();

  const submitSocialLead = ({
    phone,
    conversationDetails,
    ...values
  }: OnlineCustomerFormValues) => {
    const { comment, plannedDateOfVisit, dateOfConversation } =
      conversationDetails;
    const leadFormValue: NewSocialLead = {
      ...values,
      phone: phone || undefined,
      conversationDetails: {
        comment: comment ? comment.trim() : undefined,
        dateOfConversation: startOfDay(
          parse(dateOfConversation, 'MM/dd/yyyy', new Date())
        ).toISOString(),
        plannedDateOfVisit:
          plannedDateOfVisit &&
          endOfDay(
            parse(plannedDateOfVisit, 'MM/dd/yyyy', new Date())
          ).toISOString(),
      },
      screenshots: values.screenshots,
    };

    submitSocialLeadMutation.mutate(leadFormValue, {
      onError: () =>
        notifications.error(
          "We couldn't submit the form. Please, try again later"
        ),
    });
  };

  if (!submitSocialLeadMutation.isSuccess) {
    return (
      <form onSubmit={methods.handleSubmit(submitSocialLead)}>
        <Screen
          header={<NavBackButton onClick={() => navigate('/social-leads')} />}
          footer={
            <LoadingButton
              type="submit"
              wide
              size="m"
              disabled={submitSocialLeadMutation.isPending}
              loading={submitSocialLeadMutation.isPending}
            >
              Submit
            </LoadingButton>
          }
        >
          <FormProvider {...methods}>
            <CreateOnlineCustomer />
          </FormProvider>
        </Screen>
      </form>
    );
  }

  const resetPage = () => {
    methods.reset();
    submitSocialLeadMutation.reset();
  };

  return (
    <Screen header={<HeaderWithLogo />}>
      <Success
        onBack={() =>
          navigate(`/social-leads/${submitSocialLeadMutation.data.id}`)
        }
        onCreateNew={resetPage}
      />
    </Screen>
  );
};
