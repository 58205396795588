import { useNavigate, useParams } from 'react-router-dom';

import {
  getProductTypeFromItems,
  recoverLoanCalculator,
} from '~widgets/loan-calculator/lib';

import type {
  ApplicationDetails,
  ApplicationProcessStatus,
} from '~entities/application';
import { useSearchOfferByPhoneMutation } from '~entities/offer';
import { selectedPosAtom } from '~entities/pos';

import { LoadingButton } from '@breeze-platform-ui/button';
import Island from '@breeze-platform-ui/island';
import { useAtomValue } from 'jotai';

import { ProcessStatusTitle } from '../process-status-title/process-status-title';

type Props = {
  processStatus: ApplicationProcessStatus;
  applicationDetails: ApplicationDetails;
};
export const Onboarding = ({ processStatus, applicationDetails }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const selectedPos = useAtomValue(selectedPosAtom);

  const goToApplication = () =>
    navigate(`/loan-application/${id}`, { replace: true });

  const searchOfferMutation = useSearchOfferByPhoneMutation({
    onSuccess(response) {
      if (response.status === 'SUCCESS') {
        navigate('/loan-calculator', {
          state: {
            loanCalculator: recoverLoanCalculator(applicationDetails),
            forcedOffer: {
              offer: response,
              phone: applicationDetails.person.mobilePhone,
            },
          },
        });
      } else {
        goToApplication();
      }
    },
    onError() {
      goToApplication();
    },
  });
  const productType = getProductTypeFromItems(
    !!selectedPos?.hasZeroRateProducts,
    applicationDetails.orderItems
  );

  const shouldCheckForOffer =
    !applicationDetails.offer?.id && productType !== 'LOAN_ZERO_RATE';

  const handleContinueClick = () => {
    if (shouldCheckForOffer) {
      searchOfferMutation.mutate(applicationDetails.person.mobilePhone);
    } else {
      goToApplication();
    }
  };

  return (
    <Island
      theme="shadow"
      text="Continue filling out&nbsp;the&nbsp;application or cancel it if not needed anymore"
      title={<ProcessStatusTitle processStatus={processStatus} />}
      footerBody={
        <LoadingButton
          wide
          size="m"
          onClick={handleContinueClick}
          loading={searchOfferMutation.isPending}
        >
          Continue application
        </LoadingButton>
      }
    />
  );
};
