export const TimeOutIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M1.5 12a9 9 0 0 1 12.771-8.175 1.126 1.126 0 0 1-.944 2.044 6.75 6.75 0 1 0 0 12.263 1.124 1.124 0 1 1 .946 2.041A9.001 9.001 0 0 1 1.5 12Z"
    />
    <path
      fill="currentColor"
      d="M16.867 7.042a1.125 1.125 0 0 0-.075 1.59l2.04 2.243h-8.707a1.125 1.125 0 1 0 0 2.25h8.707l-2.04 2.242a1.125 1.125 0 1 0 1.665 1.515l3.75-4.125a1.122 1.122 0 0 0-.003-1.518l-3.747-4.122a1.125 1.125 0 0 0-1.59-.075Z"
    />
  </svg>
);
