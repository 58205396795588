import type { BreakStatus } from '~entities/attendance';
import { CommuteIcon, LunchIcon } from '~entities/attendance';
import { BreakIcon } from '~entities/attendance';
import { AttendanceStatus } from '~entities/attendance';

import Button from '@breeze-platform-ui/button';
import Island from '@breeze-platform-ui/island';
import { Box, Row } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';

import styles from './return-to-work-action.module.css';

const breakStatusNaming: Record<BreakStatus, string> = {
  [AttendanceStatus.BreakIn]: 'On a break',
  [AttendanceStatus.CommuteIn]: 'Commuting',
  [AttendanceStatus.LunchIn]: 'Lunch',
};

const statusToIcon: Record<
  BreakStatus,
  // eslint-disable-next-line @typescript-eslint/no-restricted-types
  React.FC<React.SVGAttributes<SVGSVGElement>>
> = {
  [AttendanceStatus.BreakIn]: BreakIcon,
  [AttendanceStatus.CommuteIn]: CommuteIcon,
  [AttendanceStatus.LunchIn]: LunchIcon,
};

export const ReturnToWorkAction = ({
  status,
  onClick,
}: {
  status: BreakStatus;
  onClick: () => void;
}) => {
  const IconComponent = statusToIcon[status];

  return (
    <Island theme="shadow" dataQaType="return-to-work-action">
      <Box padding="16px 16px 16px 20px">
        <Row
          gaps={8}
          margin="0 0 16px 0"
          alignMain="center"
          alignCross="center"
        >
          <IconComponent width={24} height={24} color="rgba(0,0,0,0.8)" />
          <h5 className={styles.title}>{breakStatusNaming[status]}</h5>
        </Row>
        <Button wide size="l" onClick={onClick}>
          <Text bold size={15}>
            Return to work
          </Text>
        </Button>
      </Box>
    </Island>
  );
};
