import { useLocation, useNavigate } from 'react-router-dom';

import Button from '@breeze-platform-ui/button';
import type PlatformIcon from '@breeze-platform-ui/icon';
import Text from '@breeze-platform-ui/text';

import styles from './navigation-button.module.css';

type Props = {
  route: string;
  Icon: typeof PlatformIcon;
  name: string;
  notification?: React.ReactElement;
  isHome?: boolean;
};

export const NavigationButton = ({
  route,
  Icon,
  name,
  notification,
  isHome,
}: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isActive = route === pathname || (isHome && pathname === '/');

  return (
    <Button
      onClick={() => navigate(route)}
      theme={isActive ? 'flatLight' : 'flat'}
      size="s"
      style={{ position: 'relative' }}
    >
      <Icon
        cursor="pointer"
        theme={isActive ? { color: 'var(--tds-color-accent)' } : 'gray'}
      />

      <div className={styles.buttonName}>
        <Text
          size={11}
          color={isActive ? 'var(--tds-color-accent)' : '#9299A2'}
          align="center"
          whiteSpace="nowrap"
          overflowEllipsis
        >
          {name}
        </Text>
      </div>

      {!isActive && notification}
    </Button>
  );
};
