import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  isBarcodeDetectorSupported,
  BarcodeDetectionButton,
} from '~widgets/barcode-detection';

import type { ManufacturerIdType } from '~entities/product';

import { Input, MaskedInput } from '@breeze/rhf-adapters';

import type { LoanDetailsFormValues } from '../../types';

type Props = {
  index: number;
  type?: ManufacturerIdType;
  onBlur: () => void;
  onBarcodeDetectorClick: () => void;
};

export const ManufacturerIdNumber = ({
  type,
  index,
  onBlur,
  onBarcodeDetectorClick,
}: Props) => {
  const { control } = useFormContext<LoanDetailsFormValues>();
  const [barcodeDetectionSupported, setBarcodeDetectionSupported] =
    useState(false);

  useEffect(() => {
    isBarcodeDetectorSupported()
      .then((isSupported) => setBarcodeDetectionSupported(isSupported))
      .catch(() => setBarcodeDetectionSupported(false));
  }, []);

  if (type === 'IMEI') {
    return (
      <Controller
        control={control}
        name={`itemsDetails.${index}.itemManufacturerId`}
        rules={{
          required: 'Enter the device IMEI',
          pattern: {
            value: /^\d{15}$/,
            message: 'Enter the full device IMEI',
          },
        }}
        render={(fieldProps) => (
          <MaskedInput
            {...fieldProps}
            field={{
              ...fieldProps.field,
              value: fieldProps.field.value || '',
              onBlur,
              onChange: (_event, params) => {
                fieldProps.field.onChange(params.maskedValue);
              },
            }}
            partialPlaceholder
            label="IMEI"
            mask="000000000000000"
            maskTransitions={{ 0: /\d/i }}
            placeholder="000000000000000"
            hintBlock={
              barcodeDetectionSupported ? (
                <BarcodeDetectionButton onClick={onBarcodeDetectorClick} />
              ) : undefined
            }
          />
        )}
      />
    );
  }

  const label =
    type === 'SERIAL_NUMBER' ? 'Serial number' : 'IMEI or Serial Number';
  const requiredErrorMessage =
    type === 'SERIAL_NUMBER'
      ? 'Enter the device serial number'
      : 'Enter IMEI or Serial Number';

  return (
    <Controller
      control={control}
      name={`itemsDetails.${index}.itemManufacturerId`}
      rules={{
        required: requiredErrorMessage,
      }}
      render={(fieldProps) => (
        <Input
          {...fieldProps}
          field={{
            ...fieldProps.field,
            value: fieldProps.field.value || '',
            onBlur,
          }}
          label={label}
          hintBlock={
            barcodeDetectionSupported ? (
              <BarcodeDetectionButton onClick={onBarcodeDetectorClick} />
            ) : undefined
          }
        />
      )}
    />
  );
};
