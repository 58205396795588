import { useDi } from '~app/providers/di-provider';

import type { OrderItemCategory } from '~entities/order-item';
import { selectedPosAtom } from '~entities/pos';

import { useQueryStatusHandlers } from '~shared/react-query-helpers/hooks/use-query-status-handlers';
import type { RequestError } from '~shared/scp-client';
import { nonNullableValue } from '~shared/types/non-nullable-value';

import {
  Enabled,
  PlaceholderDataFunction,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import type { ProductValidationError } from './product-validation-error';
import { isProductValidationError } from './product-validation-error';

import type { ProductRequestParams } from '../api';
import { isItemNotFoundError } from '../api';
import type { ZeroRateItem } from '../product';
import type { OrderParams, Product } from '../product';

type QueryParams = Pick<ProductRequestParams, 'orderItems' | 'productType'> & {
  offerProductId?: string;
};
type GetProductResponse = Product | OrderParams;

export const useGetProductQuery = (
  params: QueryParams,
  options?: {
    enabled: Enabled<GetProductResponse, Error>;
  },
  resultHandlers?: {
    onSettled?: (
      data: GetProductResponse | undefined,
      error: Error | null
    ) => void;
  }
) => {
  const { productApi } = useDi();
  const selectedPos = useAtomValue(selectedPosAtom);

  const queryResult = useQuery({
    queryKey: ['products', params],
    queryFn: async () => {
      const selectedPOS = nonNullableValue(selectedPos);

      try {
        return await productApi.getProduct({
          channel: 'OFFLINE',
          pointOfSaleId: selectedPOS.id,
          merchantId: selectedPOS.merchantId,
          ...params,
        });
      } catch (error) {
        if (isProductValidationError(error as RequestError)) {
          const validationParams = (error as ProductValidationError).body;

          return {
            maxOrderTotalAmount: validationParams.maxOrderTotalAmount,
            minOrderTotalAmount: validationParams.minOrderTotalAmount,
          };
        }

        throw error;
      }
    },
    refetchOnMount: true,
    ...options,
  });
  useQueryStatusHandlers({
    queryResult,
    ...resultHandlers,
  });
  return queryResult;
};

export const useGetItemsCategoriesQuery = (
  options?: Omit<
    UseQueryOptions<unknown, Error, OrderItemCategory[]>,
    'refetchOnMount' | 'retry'
  >
) => {
  const { productApi } = useDi();

  return useQuery({
    queryKey: ['items-categories'],
    queryFn: () => productApi.getCategories(),
    refetchOnMount: true,
    retry: true,
    ...options,
  });
};

export const useGetZeroRateItems = (
  selectedItemIds: string[] = [],
  options?: {
    enabled: Enabled<any, Error, Record<string, ZeroRateItem>>;
    placeholderData: PlaceholderDataFunction<
      any,
      Error,
      Record<string, ZeroRateItem>
    >;
  }
) => {
  const { productApi } = useDi();
  const selectedPos = nonNullableValue(useAtomValue(selectedPosAtom));

  return useQuery({
    queryKey: ['zero-rate-items', selectedPos.id, ...selectedItemIds],
    queryFn: async () => {
      if (!selectedPos.hasZeroRateProducts) {
        return {};
      }

      const items = await productApi.getZeroRateItems({
        pointOfSaleId: selectedPos.id,
        selectedItemIds,
      });

      return items.reduce(
        (result, item) => {
          result[item.id] = item;

          return result;
        },
        {} as Record<string, ZeroRateItem>
      );
    },
    refetchOnMount: true,
    retry: (count, error) => {
      if (isItemNotFoundError(error as RequestError)) {
        return false;
      }

      return count < 3;
    },
    refetchInterval: 15 * 60 * 1000,
    ...options,
  });
};
