import { Col } from '@breeze-platform-ui/layout';

import styles from './problems-tooltip.module.css';

export const ProblemsTooltip = () => (
  <Col gaps={12} alignCross="stretch">
    <p className={styles.text}>Possible reasons:</p>
    <p className={styles.text}>
      1. Notifications for the&nbsp;Viber&nbsp;app are turned&nbsp;off, or
      the&nbsp;app is&nbsp;deleted from the&nbsp;phone. Tap &quot;Request
      a&nbsp;new code via&nbsp;SMS&quot;.
    </p>
    <p className={styles.text}>
      2. The&nbsp;mobile number isn’t&nbsp;registered yet.
    </p>
    <p className={styles.text}>
      3. The&nbsp;SIM card linked to&nbsp;this mobile number isn’t&nbsp;working
      or installed in&nbsp;another mobile device.
    </p>
    <p className={styles.text}>4. Network or connectivity issues.</p>
  </Col>
);
