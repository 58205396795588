import React, { createContext } from 'react';

import type { Tracking } from '@breeze/web-analytics';

const TrackingContext = createContext<null | Tracking | undefined>(null);

export const TrackingProvider = (
  props: React.PropsWithChildren<{ tracking?: Tracking }>
) => {
  const { children, tracking } = props;

  return (
    <TrackingContext.Provider value={tracking}>
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = (): Tracking | undefined => {
  const maybeTracking = React.useContext(TrackingContext);

  if (maybeTracking === null) {
    throw new Error('useTracking must be used within a AnalyticsProvider');
  }

  return maybeTracking;
};
