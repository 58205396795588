import { useState } from 'react';

import { ToggleSwitch } from '~shared/ui/toggle-switch';

import Clickable from '@breeze-platform-ui/clickable';
import { Int16ArrowDown } from '@breeze-platform-ui/iconsPack';
import { Box, Col, Row } from '@breeze-platform-ui/layout';

import styles from './offer-container.module.css';

import {
  offerSourceOptions,
  type OfferSource,
} from '../../lib/offer-source-options';

export const OfferContainer = ({
  isOpened,
  onToggle,
  children,
}: {
  isOpened: boolean;
  onToggle: () => void;
  children: (source: OfferSource) => React.ReactNode;
}) => {
  const [offerSource, setOfferSource] = useState<OfferSource>('MOBILE');

  return (
    <Col gaps={16} alignCross="stretch" dataQaType="special-offer">
      <Clickable
        style={{
          backgroundColor: 'transparent',
          cursor: 'pointer',
          border: 'none',
        }}
        onClick={onToggle}
        dataQaType="toggle-special-offer"
      >
        <Row
          alignMain="between"
          alignCross="center"
          style={{ cursor: 'pointer' }}
        >
          <h3 className={styles.title}>Special offer</h3>
          <Int16ArrowDown
            theme={{ color: 'var(--tds-color-text-01)' }}
            rotate={isOpened ? 180 : 0}
          />
        </Row>
      </Clickable>

      <Col hidden={!isOpened} gaps={20} alignCross="stretch">
        <ToggleSwitch<OfferSource>
          options={offerSourceOptions}
          value={offerSource}
          onChangeValue={setOfferSource}
        />
        {children(offerSource)}
      </Col>
      {!isOpened && <Box padding="0 0 4px 0" />}
    </Col>
  );
};
