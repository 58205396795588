import type { Validate } from 'react-hook-form';

import type { FileItem } from '@breeze/rhf-adapters';

type Options = {
  max: number;
  message?: string;
};

export function maxFiles<TFormValues>(
  options: Options
): Validate<FileItem | FileItem[] | undefined, TFormValues> {
  return function fileRequiredValidator(files): string | boolean | undefined {
    if (!Array.isArray(files)) {
      return undefined;
    }

    if (files.length > options.max) {
      return options.message;
    }

    return undefined;
  };
}
