import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { combineValidators } from '~shared/lib/combine-validators';
import { validators } from '~shared/validators';

import { Row } from '@breeze-platform-ui/layout';
import { InputPhone } from '@breeze/rhf-adapters';

import type { ManageOfferFormValue } from '../manage-offer-by-phone/manage-offer-by-phone';
import { SubmitButton } from '../submit-button/submit-button';

export const OfferDetailsForm = ({
  isLoading,
  isError,
  onSubmitOfferPhone,
}: {
  isLoading?: boolean;
  isError?: boolean;
  onSubmitOfferPhone: (phone: string | undefined) => void;
}) => {
  const { handleSubmit, control, formState, watch, setError } =
    useFormContext<ManageOfferFormValue>();
  const { isValid } = formState;
  const phoneValue = watch('offerPhone');

  const handlePhoneSubmit = ({ offerPhone }: ManageOfferFormValue) => {
    onSubmitOfferPhone(offerPhone);
  };

  useEffect(() => {
    if (isError) {
      setError('offerPhone', { message: "Couldn't find any special offers" });
    }
  }, [isError, setError]);

  return (
    <form
      onBlur={(event) => event.stopPropagation()}
      onSubmit={(event, ...restArgs) => {
        event.stopPropagation();
        handleSubmit(handlePhoneSubmit)(event, ...restArgs);
      }}
    >
      <Row gaps={8} alignMain="between" alignCross="start">
        <Controller
          name="offerPhone"
          control={control}
          rules={{
            validate: combineValidators(
              validators.phoneLength(),
              validators.mobilePhoneFormat()
            ),
          }}
          render={(fieldProps) => (
            <InputPhone {...fieldProps} label="Mobile number" />
          )}
        />
        <SubmitButton
          disabled={!isValid || !phoneValue}
          isLoading={isLoading}
        />
      </Row>
    </form>
  );
};
