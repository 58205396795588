import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { getMaxItemsAmount } from '~features/manage-order-items/lib/get-max-items-amount';
import { getZeroRateItemsOptions } from '~features/manage-order-items/lib/get-zero-rate-item-options';

import type { OrderItem } from '~entities/order-item';
import type { ZeroRateItem } from '~entities/product';

import { phMoneyProps } from '~shared/constants/ph-money-props';
import { SectionTitle } from '~shared/ui/section-title';
import { validators } from '~shared/validators';

import { Col } from '@breeze-platform-ui/layout';
import { NotificationInline } from '@breeze-platform-ui/notification';
import { InputCount, InputMoney, Select } from '@breeze/rhf-adapters';

import { OrderItemConverter } from './order-item-converter';

import {
  NAME_LABEL,
  PRICE_LABEL,
  PRICE_REQUIRED_ERROR,
  QUANTITY_LABEL,
  QUANTITY_REQUIRED_ERROR,
  SELECT_ITEM_REQUIRED_ERROR,
} from '../../constants';
import { ItemFormContainer } from '../item-form-container/item-form-container';

export type FormValue = {
  zeroRateItemId: string;
  price: string;
  quantity: string | number;
};

type Props = {
  initialValue?: Partial<OrderItem>;
  zeroRateItems: Record<string, ZeroRateItem>;
  isLoading?: boolean;
  totalItemsAmount?: number;
  onSubmit: (value: OrderItem) => void;
  onCancel: () => void;
};

export const ZeroRateItemForm = ({
  initialValue,
  zeroRateItems,
  isLoading,
  totalItemsAmount = 0,
  onSubmit,
  onCancel,
}: Props) => {
  const { control, handleSubmit, reset, watch } = useForm<FormValue>({
    mode: 'all',
    defaultValues: OrderItemConverter.toFormValue(initialValue),
  });

  useEffect(() => {
    reset(OrderItemConverter.toFormValue(initialValue));
  }, [initialValue, reset]);

  const handleSubmitAndReset = (value: FormValue) => {
    const selectedItem = zeroRateItems[value.zeroRateItemId];

    onSubmit(OrderItemConverter.fromFormValue(value, selectedItem));
    reset(OrderItemConverter.toFormValue({}));
  };

  const newItem = !initialValue?.zeroRateItemId;
  const title = <span>{newItem ? 'Add' : 'Edit'} an&nbsp;item</span>;
  const options = useMemo(
    () => getZeroRateItemsOptions(zeroRateItems),
    [zeroRateItems]
  );

  const currentItemId = watch('zeroRateItemId');
  const hasMultiChoice = zeroRateItems?.[currentItemId]?.eligibleForMultiChoice;
  const maxItemsAmount = getMaxItemsAmount('LOAN_ZERO_RATE', hasMultiChoice);
  const maxAvailableItems = maxItemsAmount - totalItemsAmount;

  return (
    <form
      onBlur={(event) => event.stopPropagation()}
      onSubmit={(event, ...restArgs) => {
        event.stopPropagation();
        handleSubmit(handleSubmitAndReset)(event, ...restArgs);
      }}
    >
      <ItemFormContainer onBack={onCancel}>
        <SectionTitle title={title} margin="0 0 24px 0" />
        <Col gaps={20} alignCross="stretch">
          <NotificationInline
            showClose={false}
            type="warning"
            animateFirstRender={false}
            hideOnAction={false}
            hideOnCancel={false}
            timer={false}
          >
            Check if the&nbsp;item is&nbsp;in&nbsp;stock
          </NotificationInline>
          <Controller
            control={control}
            name="zeroRateItemId"
            rules={{ required: SELECT_ITEM_REQUIRED_ERROR }}
            render={(fieldProps) => (
              <Select
                label={NAME_LABEL}
                {...fieldProps}
                options={options}
                loading={isLoading}
                withSearch
              />
            )}
          />
          <Controller
            control={control}
            name="price"
            rules={{ required: PRICE_REQUIRED_ERROR }}
            render={(fieldProps) => (
              <InputMoney
                label={PRICE_LABEL}
                currencyInValue
                precision={2}
                max={999999}
                {...phMoneyProps}
                {...fieldProps}
              />
            )}
          />
          {hasMultiChoice && (
            <Controller
              control={control}
              name="quantity"
              rules={{
                validate: validators.required({
                  text: QUANTITY_REQUIRED_ERROR,
                }),
              }}
              render={(fieldProps) => (
                <InputCount
                  label={QUANTITY_LABEL}
                  min={1}
                  max={maxAvailableItems}
                  {...fieldProps}
                />
              )}
            />
          )}
        </Col>
      </ItemFormContainer>
    </form>
  );
};
