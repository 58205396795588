import Badge from '@breeze-platform-ui/badge';
import Text from '@breeze-platform-ui/text';

import styles from './marketing-lead-item.module.css';

import type { CustomerFeedback, MarketingLead } from '../../marketing-lead';

export const FeedbackBadge = ({ feedback }: { feedback: CustomerFeedback }) => {
  switch (feedback) {
    case 'INTERESTED':
      return (
        <Badge size="m" theme="success">
          Interested
        </Badge>
      );
    case 'UTILIZED':
      return (
        <Badge size="m" theme="success">
          Utilized
        </Badge>
      );
    case 'NOT_INTERESTED':
      return (
        <Badge size="m" theme="neutral">
          Not interested
        </Badge>
      );
    case 'APPLIED_BUT_REJECTED':
      return (
        <Badge size="m" theme="neutral">
          Applied, but rejected
        </Badge>
      );
    case 'HAVENT_ANSWERED':
      return (
        <Badge size="m" theme="warning">
          Not answering
        </Badge>
      );
    case 'UNDECIDED':
      return (
        <Badge size="m" theme="warning">
          Undecided
        </Badge>
      );
    case 'CANNOT_BE_REACHED_WRONG_NUMBER':
      return (
        <Badge size="m" theme="warning">
          Cannot be reached
        </Badge>
      );
  }
};

export const MarketingLeadItem = ({
  firstName,
  lastName,
  conversationDetails,
  note,
}: MarketingLead) => {
  const feedback = conversationDetails?.feedback;
  return (
    <div>
      <div className={styles.header}>
        <Text size={15} overflowEllipsis align="left">
          {firstName}&nbsp;{lastName}
        </Text>

        {feedback && <FeedbackBadge feedback={feedback} />}
      </div>
      {note && (
        <Text color="#0000008A" size={13} overflowEllipsis align="left">
          {note}
        </Text>
      )}
    </div>
  );
};
