import type { ComponentProps } from 'react';

import { Int16Tip } from '@breeze-platform-ui/iconsPack';
import { Col } from '@breeze-platform-ui/layout';
import List from '@breeze-platform-ui/list';

import src from './photo-requirement.png';
import styles from './photo-requirements.module.css';

const CheckMarker = <Int16Tip theme="blue" />;
const listItems: ComponentProps<typeof List>['items'] = [
  { value: 'Face must be in the center of the photo', bullet: CheckMarker },
  { value: 'Eyes should be open', bullet: CheckMarker },
  {
    value: 'No sunglasses or other items covering the face',
    bullet: CheckMarker,
  },
];

export const PhotoRequirements = () => {
  return (
    <div className={styles.container}>
      <Col gaps={10}>
        <h4 className={styles.title}>Photo requirements</h4>
        <List items={listItems} size="s" textColor="rgba(0, 0, 0, 0.54)" />
      </Col>
      <figure className={styles.imageContainer}>
        <img
          src={src}
          alt="example which corresponds with listed requirements"
        />
        <figcaption className={styles.imageCaption}>Example</figcaption>
      </figure>
    </div>
  );
};
