import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AgentPhone } from '~widgets/agent-phone/agent-phone';
import { AgentSmsConfirmation } from '~widgets/agent-sms-confirmation';
import { AppLoadingPage } from '~widgets/app-loading-page';

import { AuthorizationError, clearOldRequestsData } from '~features/auth/otp';
import { setOtpRequestTime } from '~features/auth/otp/lib';

import { useAgentInfo } from '~entities/agent';
import {
  agentPhoneAtom,
  isAuthorizedAtom,
  userInfoAtom,
  useInitiateMutation,
  useGetUserInfoQuery,
} from '~entities/auth';

import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScrollIntoView } from '~shared/ui/scroll-into-view';
import { Stepper, Step } from '~shared/ui/stepper';

import { useAtom, useSetAtom } from 'jotai';

const stepsNames = {
  phoneForm: 'phoneForm',
  smsConfirmation: 'smsConfirmation',
  roleReceiving: 'roleReceiving',
};

export const AgentLoginPage = () => {
  const [initialAgentPhone, updateAgentPhone] = useAtom(agentPhoneAtom);
  const setIsAuthorized = useSetAtom(isAuthorizedAtom);
  const setUserInfo = useSetAtom(userInfoAtom);
  const [currentStep, setCurrentStep] = useState(stepsNames.phoneForm);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [initiateError, setInitiateError] = useState<Error | null>(null);
  const {
    data: userInfo,
    isError,
    isSuccess: isUserInfoReceived,
  } = useGetUserInfoQuery({
    queryKey: ['agent-user-info'],
    enabled: currentStep === stepsNames.roleReceiving,
  });
  const navigate = useNavigate();
  const handleConfirmationSuccess = () => {
    setCurrentStep(stepsNames.roleReceiving);
  };

  const isAgentTypeQueryEnabled = !!userInfo && isUserInfoReceived;

  const agentTypeQuery = useAgentInfo({
    select: (info) => info.employmentType,
    enabled: isAgentTypeQueryEnabled,
  });
  const isAgentTypeLoading =
    isAgentTypeQueryEnabled && agentTypeQuery.isLoading;

  const { mutate: initiate, isPending } = useInitiateMutation({
    onSuccess: () => {
      setOtpRequestTime('login', phoneNumber, new Date().getTime());
      setCurrentStep(stepsNames.smsConfirmation);
    },
    onError: (error) => {
      setInitiateError(error);
    },
  });

  useEffect(() => {
    if (phoneNumber) {
      initiate({ authParameters: { phoneNumber } });
      updateAgentPhone(phoneNumber);
    }
  }, [phoneNumber, initiate, updateAgentPhone]);

  const agentType = agentTypeQuery.data;

  useEffect(() => {
    if (isError) {
      setUserInfo(null);
      setIsAuthorized(true);
    }

    if (userInfo && !isAgentTypeLoading) {
      const nextPageAfterAuth =
        !isAgentTypeQueryEnabled || agentType === 'CONTRACTOR'
          ? '/pos-select?redirectUrl=/applications'
          : '/account';

      clearOldRequestsData('login');
      clearOldRequestsData('agreement');
      // agent must select current pos after successfull authorization
      // for now we always reload the page after login
      window.location.replace(nextPageAfterAuth);
    }
  }, [
    isError,
    userInfo,
    setUserInfo,
    setIsAuthorized,
    navigate,
    isAgentTypeLoading,
    agentType,
    isAgentTypeQueryEnabled,
  ]);

  if (initiateError) {
    // waiting for new UX to show initiate error on entering mobile screen
    return (
      <Screen header={<FormHeader href="/" />}>
        <AuthorizationError error={initiateError} />
      </Screen>
    );
  }
  return (
    <Stepper current={currentStep}>
      <Step name={stepsNames.phoneForm}>
        <ScrollIntoView>
          <AgentPhone
            onSubmit={setPhoneNumber}
            isLoading={isPending}
            initialPhone={initialAgentPhone}
          />
        </ScrollIntoView>
      </Step>
      <Step name={stepsNames.smsConfirmation}>
        <ScrollIntoView>
          <AgentSmsConfirmation
            phoneNumber={phoneNumber}
            onSuccess={handleConfirmationSuccess}
          />
        </ScrollIntoView>
      </Step>
      <Step name={stepsNames.roleReceiving}>
        <ScrollIntoView>
          <AppLoadingPage />
        </ScrollIntoView>
      </Step>
    </Stepper>
  );
};
