import { useNavigate } from 'react-router-dom';

import {
  SocialLeadsList,
  SocialLeadsSkeleton,
} from '~widgets/social-leads-list';

import { useSocialLeadsList } from '~entities/social-lead';

import { NavBackButton } from '~shared/ui/nav-back-button';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import Button from '@breeze-platform-ui/button';
import { Box, Row } from '@breeze-platform-ui/layout';
import Loader from '@breeze-platform-ui/loader';

import { EmptyWarning } from './ui/empty-warning/empty-warning';
import { Error } from './ui/error/error';

export const SocialLeadsPage = () => {
  const leadsQuery = useSocialLeadsList();
  const navigate = useNavigate();

  const handleCreateNewLeadClick = () => {
    navigate('/social-leads/new');
  };

  const handleBackClick = () => navigate('/account');

  if (!!leadsQuery.data?.length || leadsQuery.isLoading) {
    return (
      <Screen
        header={<NavBackButton onClick={handleBackClick} />}
        footer={
          <Button
            size="m"
            wide
            href="/social-leads/new"
            onClick={(e) => {
              e.preventDefault();
              handleCreateNewLeadClick();
            }}
          >
            Submit a new form
          </Button>
        }
      >
        <Row alignCross="start">
          <ScreenTitle title="My&nbsp;leads" />{' '}
          {leadsQuery.isFetching && leadsQuery.isStale && (
            <Box padding="8px 16px">
              <Loader size="s" />
            </Box>
          )}
        </Row>
        {leadsQuery.isLoading ? (
          <SocialLeadsSkeleton />
        ) : (
          <SocialLeadsList
            leads={leadsQuery.data ?? []}
            onLeadClick={(id) => navigate(`/social-leads/${id}`)}
          />
        )}
      </Screen>
    );
  }

  return (
    <Screen header={<NavBackButton onClick={handleBackClick} />}>
      {leadsQuery.isError ? (
        <Error onBack={handleBackClick} />
      ) : (
        <EmptyWarning
          onAdd={handleCreateNewLeadClick}
          onBack={handleBackClick}
        />
      )}
    </Screen>
  );
};
