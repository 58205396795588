import type { OrderItem } from '~entities/order-item';

import { phMoneyProps } from '~shared/constants/ph-money-props';

import { Row } from '@breeze-platform-ui/layout';
import Money from '@breeze-platform-ui/money';

type Props = {
  item: OrderItem;
  actions?: React.ReactNode;
};

export const OrderItemUI = ({ item, actions }: Props) => {
  return (
    <Row gaps={24} alignMain="between" dataQaType="order-list-item">
      <div>
        <div className="break-all text-body-m" data-qa-type="order-item-name">
          {item.name}
        </div>
        <div className="text-body-s text-text-secondary">
          <span data-qa-type="order-item-quantity">{item.quantity}</span>
          &nbsp;×&nbsp;
          <Money
            {...phMoneyProps}
            dataQaType="order-item-price"
            value={item.price}
          />
        </div>
      </div>
      <Row gaps={20}>
        <div
          className="break-all text-body-m"
          data-qa-type="order-item-total-price"
        >
          <Money {...phMoneyProps} value={+item.price * +item.quantity} />
        </div>
        {actions}
      </Row>
    </Row>
  );
};
