import type { ComponentProps } from 'react';
import React from 'react';

import { Box } from '@breeze-platform-ui/layout';

import styles from './screen-title.module.css';

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
} & Omit<ComponentProps<typeof Box>, 'title' | 'subtitle'>;

export const ScreenTitle = ({ title, subtitle, ...boxProps }: Props) => {
  return (
    <Box color="var(--tds-color-text-01)" margin="0 0 24px 0" {...boxProps}>
      {title && <h2 className={styles.title}>{title}</h2>}
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
    </Box>
  );
};
