import { useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FinalLoanCalculatorForm } from '~widgets/final-calculator/ui/final-calculator-form';

import {
  useGetApplicationByIdQuery,
  useConfirmProductMutation,
} from '~entities/application';
import { useGiftPaymentRatesByTerm } from '~entities/gift-payment';

import { nonNullableValue } from '~shared/types/non-nullable-value';
import { Loader } from '~shared/ui/loader';
import { NavBackButton } from '~shared/ui/nav-back-button/nav-back-button';
import { Screen } from '~shared/ui/screen';

export const ProductConfirmationPage = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const getApplicationByIdQuery = useGetApplicationByIdQuery(id);

  const hasOffer =
    getApplicationByIdQuery.isSuccess &&
    !!getApplicationByIdQuery.data.offer?.id;
  const hasZeroRateItems = getApplicationByIdQuery.data?.orderItems?.find(
    (item) => item.zeroRateItemId
  );

  const shouldOfferGiftPayments = !hasOffer && !hasZeroRateItems;
  const giftPaymentRatesByTermQuery = useGiftPaymentRatesByTerm({
    enabled: shouldOfferGiftPayments,
  });

  const {
    mutate,
    isPending: isConfirmProductLoading,
    isSuccess: isConfirmProductSuccess,
  } = useConfirmProductMutation(id);

  const goToApplicationDetails = useCallback(
    () => navigate(`/applications/${id}`, { replace: true }),
    [navigate, id]
  );

  useEffect(() => {
    if (isConfirmProductSuccess) {
      navigate(`/applications/${id}/loan-details`, { replace: true });
    }
  }, [isConfirmProductSuccess, id, navigate]);

  useEffect(() => {
    if (getApplicationByIdQuery.data?.agreementId) {
      goToApplicationDetails();
    }
  }, [getApplicationByIdQuery.data, goToApplicationDetails]);

  const isDataLoading =
    getApplicationByIdQuery.isLoading ||
    (shouldOfferGiftPayments && giftPaymentRatesByTermQuery.isLoading);
  const isDataError = getApplicationByIdQuery.isError;

  if (isDataLoading) {
    return (
      <Screen header={<NavBackButton onClick={goToApplicationDetails} />}>
        <Loader overlay alignTop="100px" />
      </Screen>
    );
  }

  if (isDataError) {
    return (
      <Screen header={<NavBackButton onClick={goToApplicationDetails} />}>
        Something went wrong
      </Screen>
    );
  }

  if (
    getApplicationByIdQuery.data &&
    !getApplicationByIdQuery.data.agreementId
  ) {
    return (
      <FinalLoanCalculatorForm
        approvedProductOptions={
          getApplicationByIdQuery.data.approvedProductOptions || []
        }
        requestedLoan={nonNullableValue(
          getApplicationByIdQuery.data.requestedProduct
        )}
        giftPaymentRatesByTerm={
          shouldOfferGiftPayments ? giftPaymentRatesByTermQuery.data : undefined
        }
        isLoading={isConfirmProductLoading}
        onSubmit={mutate}
        onClose={goToApplicationDetails}
      />
    );
  }

  return null;
};
