import Button from '@breeze-platform-ui/button';
import { Int16Close } from '@breeze-platform-ui/iconsPack';
import { Col, Row } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';

import styles from './offer-banner.module.css';
import starsImg from './stars.svg';

type Props = {
  title: string;
  subtitle: string;
  onCancel: () => void;
};
export const OfferBanner = ({ title, subtitle, onCancel }: Props) => {
  return (
    <Row gaps={16} alignCross="center" alignMain="between" padding="0 0 20px">
      <div className={styles.icon_container}>
        <img
          className={styles.icon_img}
          src={starsImg}
          alt=""
          height="24"
          width="24"
        />
      </div>
      <Col grow={1}>
        <Text size={15} bold dataQaType="special-offer-title">
          {title}
        </Text>
        <Text
          size={13}
          color="rgba(0, 0, 0, 0.80)"
          dataQaType="special-offer-subtitle"
        >
          {subtitle}
        </Text>
      </Col>
      <Button
        dataQaType="cancel-offer-button"
        theme="secondary"
        size="s"
        onClick={onCancel}
        icon={<Int16Close theme={{ color: 'var(--tds-color-accent)' }} />}
      />
    </Row>
  );
};
