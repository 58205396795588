import Clickable from '@breeze-platform-ui/clickable';
import { Int24ArrowRight } from '@breeze-platform-ui/iconsPack';
import Loader from '@breeze-platform-ui/loader';
import cn from 'classnames';

import styles from './submit-button.module.css';

export const SubmitButton = ({
  disabled,
  isLoading,
}: {
  disabled?: boolean;
  isLoading?: boolean;
}) => {
  return (
    <Clickable
      type="submit"
      dataQaType="submit-offer-phone-button"
      className={cn(styles.button, { [styles.disabled]: disabled })}
      disabled={disabled || isLoading}
    >
      {isLoading ? <Loader size="m" color="white" /> : <Int24ArrowRight />}
    </Clickable>
  );
};
